import React from 'react';
import CommonCardPage from '../../components/CommonCardPage/CommonCardPage';

const NotAvailable = () => {
    return (
        <CommonCardPage
            cardHeaderType={'common'}
            cardHeader={'CONTEST NOT'}
            cardSecondHeader={'AVAILABLE'}
            hasAction={true}
            url={'https://youbetyourlife.com/'}
            actionText={'YOUBETYOURLIFE.COM'}
            height={'282px'}>
            <div className='common-card-page-content'>
                <p>Sorry but this contest is not available to anyone outside of the United States. </p>
                <p>For more You Bet Your Life, please visit the website below.</p>
            </div>
        </CommonCardPage>
    )
}

export default NotAvailable;