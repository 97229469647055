import React from 'react';
import classes from './Footer.module.scss';

const Footer = () => {
    return (
        <footer className={classes.Footer}>
            <a href="https://youbetyourlife.com/terms/">Terms of Service, </a>
            <a href="https://youbetyourlife.com/privacy-policy/">Privacy Policy</a>,
            <a href="https://youbetyourlife.com/qr-rules">Sweepstakes Rules.</a>
        </footer>
    );
}

export default Footer;
