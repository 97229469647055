import React, { useState, useEffect, useContext, Fragment } from 'react';
import Loader from '../../components/UI/Loader/Loader';
import Card from '../../components/UI/Card/Card';
import classes from './Quiz.module.scss';
import CardHeader from '../../components/UI/CardHeader/CardHeader';
import Button from '../../components/UI/Button/Button';
import axios from '../../utils/axiosInstance';
import DeviceContext from '../../store/device-context';
import ParamsContext from '../../store/params-context';
import CountDown from '../../components/UI/CountDown/CountDown';
import ResponseContext from '../../store/response-context';
import { Link } from 'react-router-dom';
import CommonCardPage from '../../components/CommonCardPage/CommonCardPage';
import { withRouter } from 'react-router';
import Retry from '../Retry/Retry';

const Quiz = props => {
    const [loading, setLoading] = useState(true);
    const [showQuiz, setShowQuiz] = useState(false);
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [quizTimeout, setQuizTimeout] = useState(false);
    const [winner, setWinner] = useState(null);
    const [correctId, setCorrectId] = useState(null);
    const [categoryName, setCategoryName] = useState(null);
    const [loadingResults, setLoadingResults] = useState(null);
    const [content, setContent] = useState(null);
    const { device } = useContext(DeviceContext);
    const { params } = useContext(ParamsContext);
    const { onSetResponse, response } = useContext(ResponseContext);
    const [showRetry, setShowRetry] = useState({
        value: false,
        retryFunc: null
    })

    useEffect(() => {
        const onRedirectUrl = () => {
            if (content.type === 'url') {
                let a8_redirect = false;
                let cleanedUrl = content.url;
                if(content.url.indexOf("?") > -1) {
                    let paramsString = content.url.split('?')[1];
                    let searchParams = new URLSearchParams(paramsString);
                    if(searchParams.has('a8_redirect')) {
                        a8_redirect = Boolean(Number(searchParams.get('a8_redirect')));
                        searchParams.delete('a8_redirect');
                        cleanedUrl = content.url.split('?')[0];
                        if(searchParams.toString().length > 0) {
                            cleanedUrl += '?' + searchParams.toString()
                        }
                    }    
                }

                if(!a8_redirect){
                    props.history.push({
                        pathname: '/completed',
                        state: {
                            title: content.display_name,
                            description: stripHtml(content.description),
                            url: cleanedUrl,
                            urlText: 'YouBetYourLife.com',
                            hasAction: true,
                            trackingEnabled: params.tracking_enabled,
                            type: 'url',
                            contentId: content.id,
                            uuid: device.uuid
                        }
                    });
                } else {
                    props.history.push({
                        pathname: '/redirect',
                        state: {
                            url: cleanedUrl,
                        }
                    });                
                }
            }
        }

        const updateStatus = async status => {
            try {
                const uri = `/device/${device.uuid}/content/${content.id}?status=${status}`;
                const res = await axios.put(uri, {}, {
                    headers: {
                    'Content-Type': 'application/json',
                    'x-device-uuid': `${device.uuid}`
                    }
                });
                if (res && res.status === 200) {
                    if (res.data && res.data.message) {
                        console.log('STATUS: ', status + ' ', res.data.message);
                        return res.data;
                    }
                }
            } catch (err) {
                console.error('Error: ', err);
            }
        };
        if (content && content !== null && content.id  && ((answers.length > 0 && showQuiz) || (content.type === 'url'))) {
            if (content.type !== 'url' && params.tracking_enabled) {
                updateStatus(1);
            } else {
                onRedirectUrl();
            }
        }
    }, [content, answers, device.uuid, params.tracking_enabled, props.history, showQuiz])

    useEffect(() => {
        const checkSelectedAnswer = async() => {
            setLoadingResults(true)
            try {
                const res = await axios.post(`device/${device.uuid}/question/${content.id}/answer`, {'choice_id': selectedAnswer}, {
                    headers: {'x-device-uuid': `${device.uuid}`}
                });
                if (res && res.data) {
                    const winner = res.data.correct_choice_id === res.data.response.choice_id;
                    winner ? setWinner(true) : setWinner(false);
                    setCorrectId(res.data.correct_choice_id);
                    onSetResponse({responseId: res.data.response.id, winner: winner});
                    setLoadingResults(false)
                } else {
                    onSetResponse({responseId: '', winner: null});
                    setLoadingResults(false)
                }
            } catch(err) {
                setLoadingResults(false);
                if (err.message === 'timeout of 15000ms exceeded') {
                    setShowRetry({
                        value: true,
                        retryFunc: checkSelectedAnswer
                    });
                } else {
                    props.history.push('/error');
                }
            }
        }

        const onSetLoser = () => {
            setLoadingResults(true)
            setWinner(false);
            onSetResponse({responseId: '', winner: null});
            setLoadingResults(false)
        }

        if (quizTimeout) {
            selectedAnswer ? checkSelectedAnswer() : onSetLoser();
        }

        // eslint-disable-next-line
    }, [quizTimeout, selectedAnswer, device, content]);

    useEffect(() => {
        const getChoices = async(content) => {
            try {
                const res = await axios.get(`device/${device.uuid}/question/${content.id}/choices`, {
                    headers: {'x-device-uuid': `${device.uuid}`}
                });
                if (res && res.data) {
                    let qAns = res.data.map(el => {
                        return {
                            id: el.id,
                            text: el.text
                        }
                    });
                    setAnswers(qAns);
                }
            } catch(err) {
                setLoading(false);
                if (err.message === 'timeout of 15000ms exceeded') {
                    setShowRetry({
                        value: true,
                        retryFunc: getChoices
                    });
                } else {
                    props.history.push('/error');
                }
            }
        }

        const catchQuestion = async() => {
            setLoading(true)
            try {
                let endpoint;

                if(!params.trigger_uuid) {
                    endpoint = `qrcode/${params.triggerable_id}/catch?client_id=${params.client_id}`;
                } else {
                    endpoint = `content/deliver?trigger_uuid=${params.trigger_uuid}`;
                }

                const res = await axios.post(endpoint, {}, {
                    headers: {'x-device-uuid': `${device.uuid}`}
                });
                if (res && res.data && res.data[0]) {
                    setContent(res.data[0])
                    if (res.data[0].type === 'question') {
                        if (res.data[0].categories[0]) {
                            setCategoryName(res.data[0].categories[0].name);
                        }
                        setQuestion(res.data[0].display_name);
                        getChoices(res.data[0]);
                        setLoading(false);
                    }
                } else {
                    props.history.push('/error');
                }
            } catch(err) {
                setLoading(false);
                if (err.message === 'timeout of 15000ms exceeded') {
                    setShowRetry({
                        value: true,
                        retryFunc: catchQuestion
                    });
                } else {
                    props.history.push('/error');
                }
              }
        }

        if (!params.trigger_uuid && (!params.triggerable_id || !params.triggerable_id === null || !params.client_id || params.client_id === null)) {                        setLoading(false);
            props.history.push({
                pathname: '/completed',
                state: {
                    title: 'YOU MISSED',
                    secondTitle: 'THE LIVE CONTEST',
                    description: 'You can still play and answer, and if you guess it right, you earn SuperFan points and one you have enough SuperFan points you will win SuperFan prizes! Tap below to get started.',
                    url: 'https://youbetyourlife.com/',
                    height: '267px',
                    urlText: 'YouBetYourLife.com',
                    hasAction: true
                }
            });
        } else {
            catchQuestion();
        }
    }, [params, device, props.history, response.quizAnswered]);

    const onSelectAnswer = (id) => {
        setSelectedAnswer(id)
    }

    const handleRetry = () => {
        showRetry.retryFunc.call();
        setShowRetry({
            value: false,
            retryFunc: null
        });
    }

    const onNavigateSubmit = () => {
        if (quizTimeout && winner) {
            props.history.push('/submit');
        } else {
            props.history.push({
                pathname: '/completed',
                state: {
                    title: 'NOT A WINNER',
                    secondTitle: 'THIS TIME',
                    description: 'Sorry, better luck next time. Tune in every weekday and scan the QR Code on TV for another chance to play You Bet Your Life and try again!',
                    url: 'https://youbetyourlife.com/',
                    height: '237px',
                    urlText: 'YouBetYourLife.com',
                    hasAction: true
                }
            });
        }
    }

    const stripHtml = (html) => {
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    const loader = <Loader loadingMessage={'Preparing your question'} />;

    const quizCountdown = (
        <CountDown
            selectedAnswer={selectedAnswer}
            marginTop={'30px'}
            marginBottom={'0px'}
            duration={10}
            onComplete={() => setQuizTimeout(true)} />
    );

    const winnerHeader = (
        <Fragment>
            {
                winner !== null ? (
                    <div className={classes.WinnerHeader}>
                        <div className={classes.WinnerTitle}>
                            { winner === true ? 'CORRECT!' : winner === false ? 'WRONG ANSWER' : '' }
                        </div>
                        <div className={classes.WinnerSubTitle}>
                            {winner === true ? 'GREAT JOB' : winner === false ? 'BUMMER!' : ''}
                            <div className={classes.SelectAnswer}>
                            {winner === true && 'Please wait for the contest entry page.'}
                            </div>
                        </div>
                    </div>
                ) : <CountDown marginTop={'30px'} marginBottom={'22px'} />
            }
        </Fragment>
    );

    const letsPlay = (
        <CommonCardPage
            type={'landing'} 
            cardHeaderType={'common'}
            cardHeader={'YOU BET YOUR LIFE'}
            cardSecondHeader={'QUIZ GAME'}
            hasAction={true}
            height={'255px'}
            actionText={'Lets Play'}
            onClick={() => setShowQuiz(true)}
            displayBanner={true}
            >
            <div className="common-card-page-content">
                <p>Tap below to start playing the <a href="https://youbetyourlife.com">You Bet Your Life</a> daily quiz at home game. Get the answer right and you can enter for a chance to win today’s prize! You can only play once each day, so good luck!</p>
            </div>            
        </CommonCardPage>
    )

    const quiz = (
        <div className="common-container" style={{position: 'relative'}}>
            { !quizTimeout ? quizCountdown : winnerHeader }
            { !quizTimeout && <div className={classes.SelectAnswer}>Select your answer</div> }
            <div className={classes.QuizCardContainer}>
                <div
                    style={{
                            minHeight: '355px',
                            height: '100%',
                            width: '320px',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'center',
                            alignItems:'center'}}>
                    <Card>
                        <div style={{margin: '30px 25px 21px 25px', height: '100%'}}>
                            <CardHeader header={categoryName ? categoryName : 'question'} />
                            <p>{question}</p>
                            {
                                !loadingResults && (
                                    <div style={{height: '100%'}}>
                                        {answers.map(answer => {
                                            return (
                                                <Button
                                                    correctId={correctId}
                                                    winner={winner}
                                                    quizTimeout={quizTimeout}
                                                    selectedAnswer={selectedAnswer}
                                                    marginTop={'6px'}
                                                    click={onSelectAnswer}
                                                    id={answer.id}
                                                    key={answer.id}
                                                    type={'quiz'}>
                                                        {answer.text}
                                                </Button>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        </div>
                    </Card>
                </div>

            </div>
            {
                quizTimeout && !loadingResults && (
                    <div style={{marginTop: '15px'}}>
                        <CountDown
                            marginTop={'10px'}
                            marginBottom={'10px'}
                            duration={5}
                            onComplete={() => onNavigateSubmit()} />
                    </div>
                )
            }
        </div>
    );

    return (
        <div className="common-container" style={{position: 'relative', minHeight:'600px'}}>
            {
                loading ? loader : showQuiz ? quiz : showRetry.value === true ? <Retry onClick={handleRetry} /> : letsPlay
            }
        </div>
    );
}

export default withRouter(Quiz);
