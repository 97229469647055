import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserAgentProvider, withUserAgent } from 'react-ua';
import { DeviceContextProvider } from './store/device-context';
import { ParamsContextProvider } from './store/params-context';
import { ResponseContextProvider } from './store/response-context';
import { CookiesProvider } from 'react-cookie';

const CompWithHoc = withUserAgent(({ ua }) => (
  <DeviceContextProvider>
    <ParamsContextProvider>
      <ResponseContextProvider>
        <CookiesProvider>
          <App ua={ua} />
        </CookiesProvider>
      </ResponseContextProvider>
    </ParamsContextProvider>
  </DeviceContextProvider>
));

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <UserAgentProvider>
        <CompWithHoc />
      </UserAgentProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
