import React from 'react';
import YbylImg from '../../../images/jaylogo.png';
import cityScape from '../../../images/cityscape.svg'
import classes from './Hero.module.scss';

const Hero = () => {
    return (
        <div className={classes.HeroContainer}>
            <div className={classes.LogoImageContainer}>
                <img
                    className={classes.LogoImage}
                    src={YbylImg}
                    alt="you-bet-your-life-logo" />
            </div>
            <img
                className={classes.CityScape}
                src={cityScape}
                alt="you-bet-your-life-logo" />

        </div>
    );
}

export default Hero;
