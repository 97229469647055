import React, { useEffect, useState, useContext, Fragment } from 'react';
import classes from './Completed.module.scss';
import axios from '../../utils/axiosInstance';
import CommonCardPage from '../../components/CommonCardPage/CommonCardPage';
import { withRouter } from 'react-router';
import ParamsContext from '../../store/params-context';
import DeviceContext from '../../store/device-context';
import Loader from '../../components/UI/Loader/Loader';

const Completed = props => {

    const [clickThrough, setClickThrough] = useState(null);
    const { params } = useContext(ParamsContext);
    const { device } = useContext(DeviceContext);
    const [loading, setLoading] = useState(null);
    const [config, setConfig] = useState({
        title: null,
        height: null,
        hasAction: null,
        secondTitle: null,
        description: null,
        type: null,
        url: null,
        contentId: null,
        urlText: null
    });


    useEffect(() => {
        const handleRedirect = () => {
            props.history.push({
                pathname: '/redirect',
                state: {
                    url: config.url,
                }
            });
        }
        const updateStatus = async status => {
            try {
                const uri = `/device/${device.uuid}/content/${config.contentId}?status=${status}`;
                const res = await axios.put(uri, {}, {
                    headers: {
                    'Content-Type': 'application/json',
                    'x-device-uuid': `${device.uuid}`
                    }
                });
                if (res && res.status === 200) {
                    if (res.data && res.data.message) {
                        console.log('STATUS: ', status + ' ', res.data.message);
                        if(status === 8) { handleRedirect() }
                        return res.data;
                    }
                }
            } catch (err) {
                if (status === 8) { handleRedirect() }
                console.error('Error: ', err);
            }
        };
        if (config && config.type === 'url') {
            if (params.tracking_enabled) {
                clickThrough ? updateStatus(8) : updateStatus(1)
            } else {
                handleRedirect()
            }
        }
    }, [config, params, device.uuid, params.tracking_enabled, clickThrough, props.history]);

    useEffect(() => {
        let configToUpdate = {
            title: null,
            height: null,
            hasAction: null,
            secondTitle: null,
            description: null,
            type: null,
            url: null,
            contentId: null,
            urlText: null
        }
        if (props.location && props.location.state) {

            setLoading(true)
            const { title, height, hasAction, secondTitle, description, type, url, contentId, urlText} = props.location.state;
            configToUpdate.title = title;
            configToUpdate.height = height;
            configToUpdate.hasAction = hasAction;
            configToUpdate.secondTitle = secondTitle;
            configToUpdate.description = description
            configToUpdate.type = type;
            configToUpdate.url = url;
            configToUpdate.contentId = contentId;
            configToUpdate.urlText = urlText
        } else {
            setLoading(true)
            configToUpdate.title = 'YOU MISSED';
            configToUpdate.secondTitle = 'THE LIVE CONTEST';
            configToUpdate.height = '267px';
            configToUpdate.hasAction = true;
            configToUpdate.description = 'You can still play and answer, and if you guess it right, you earn SuperFan points and one you have enough SuperFan points you will win SuperFan prizes! Tap below to get started.'
            configToUpdate.url = 'https://youbetyourlife.com/';
            configToUpdate.urlText = 'YouBetYourLife.com'
        }
        setConfig(configToUpdate)
        setLoading(false)
    },[props.location, setConfig, setLoading]);

    const handleSetClickThrough = () => {
        setClickThrough(true)
    }

    return (
        <Fragment>
            {
                loading ? <Loader /> : (
                    <CommonCardPage
                        cardHeaderType={'common'}
                        cardHeader={config.title}
                        cardSecondHeader={config.secondTitle}
                        actionText={config.urlText}
                        height={config.height}
                        url={config.url}
                        hasAction={config.hasAction}
                        type={config.type === 'url' ? config.type : null}
                        onClick={handleSetClickThrough}>
                        <div className={classes.CompletedCopy}>
                            <p>{config.description}</p>
                        </div>
                    </CommonCardPage>
                )
            }
        </Fragment>
    );
}

export default withRouter(Completed);
