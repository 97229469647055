import React, { useState, useEffect } from 'react';
import IcCircle from '../../../images/ic-circle.svg'
import IcCheck from '../../../images/ic-checkmark.svg'
import IcClose from '../../../images/ic-close.svg';
import IcUnselect from '../../../images/ic-unselected.svg';
import IcCircleClose from '../../../images/ic-circleclose.svg'
import classes from './Button.module.scss';

const Button = props => {
    const { id, click, type, selectedAnswer, winner, quizTimeout, correctId } = props;
    const [selectedIconImage, setSelectedIconImage] = useState(IcCircle);
    const [btnClasses, setBtnClasses] = useState(['btn-component', 'panels']);
    const [btnLabelClass, setBtnLabelClass] = useState(['btn-label']);
    const [btnIconClass, setBtnIconClass] = useState(['btn-icon'])

    useEffect(() => {
        if (type === 'quiz' && selectedAnswer && selectedAnswer === id) {
            setBtnClasses(['btn-component', 'btn-selected', 'panels'])
            setSelectedIconImage(IcCircleClose);
        } else if (type === 'icon') {
            setBtnClasses(['btn-component', 'panels'])
            setSelectedIconImage(IcUnselect);
        } else {
            setBtnClasses(['btn-component', 'panels']);
            setSelectedIconImage(IcCircle);
        }
    }, [selectedAnswer, id, type]);

    useEffect(() => {
        if (type === 'quiz' && quizTimeout) {
            if (winner) {
                if (selectedAnswer === id) {
                    setSelectedIconImage(IcCheck);
                    setBtnClasses([classes.AnsweredComponent]);
                    setBtnLabelClass([classes.BtnLabelCorrect, classes.BtnLabel])
                    setBtnIconClass([classes.BtnIcon, classes.BtnIconCorrect])
                } else {
                    setSelectedIconImage(IcClose);
                    setBtnClasses([classes.AnsweredComponent]);
                    setBtnLabelClass([classes.BtnLabelGrey, classes.BtnLabel])
                    setBtnIconClass([classes.BtnIcon, classes.BtnIconGrey])

                }
            } else {
                if (selectedAnswer === id) {
                    setSelectedIconImage(IcClose);
                    setBtnClasses([classes.AnsweredComponent]);
                    setBtnLabelClass([classes.BtnLabelWrong, classes.BtnLabel])
                    setBtnIconClass([classes.BtnIcon, classes.BtnIconWrong])
                } else if (correctId === id) {
                    setSelectedIconImage(IcCheck);
                    setBtnClasses([classes.AnsweredComponent]);
                    setBtnLabelClass([classes.BtnLabelMissed, classes.BtnLabel])
                    setBtnIconClass([classes.BtnIcon, classes.BtnIconMissed])

                } else {
                    setSelectedIconImage(IcClose);
                    setBtnClasses([classes.AnsweredComponent]);
                    setBtnLabelClass([classes.BtnLabelGrey, classes.BtnLabel])
                    setBtnIconClass([classes.BtnIcon, classes.BtnIconGrey])
                }
            }
        }
    }, [winner, quizTimeout, selectedAnswer, id, type, correctId]);


    const onButtonMouseOver = (e) => {
        type === 'quiz' && !quizTimeout && setSelectedIconImage(IcCircleClose);
    }

    const onButtonMouseOut = (e) => {
        if (type === 'quiz' && selectedAnswer !== id && !quizTimeout) {
             setSelectedIconImage(IcCircle);
        }
    }

    const buttonClickHandler = (e) => {
        if (type === 'quiz') {
            if (!quizTimeout) {
                click(id);
            }
        } else {
            click();
        }
    }

    return (
        <div
            className={btnClasses.join(' ')}
            style={{marginTop: props.marginTop}}
            onClick={buttonClickHandler}
            onMouseOver={onButtonMouseOver}
            onMouseOut={onButtonMouseOut}>
            <div
                className={btnLabelClass.join(' ')}
                style={{ maxWidth: type === 'quiz' ? '224px' : type === 'icon' ? '229px' : '100%' }}>
                    {props.children}
            </div>
            {
                (type === 'quiz' || type === 'icon') && (
                    <div className={btnIconClass.join(' ')}>
                        <img src={selectedIconImage} alt={selectedIconImage}/>
                    </div>
                )
            }
            <div className="btn-inline"></div>
        </div>
    );
}

export default Button;
