import React from 'react';
import classes from './Banner.module.scss';
import BannerImg from '../../../images/MKTG-10901-YBYL-QRActivationDisplayBanner.jpg';

const Banner = props => {
    return (
        <div className="commom-container">
            <div className={classes.Banner}>
                <a href={"https://ybyl.actv8me.com?sl=76tand"}>
                    <img src={BannerImg} style={{maxWidth:"320px", borderRadius:"10px"}}/>  
                </a>
            </div>
        </div>
    );
}

export default Banner;