import React, { useEffect, useContext, useState, Fragment }from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import ParamsContext from '../../store/params-context';
import axios from '../../utils/axiosInstance';
import DeviceContext from '../../store/device-context';
import Loader from '../../components/UI/Loader/Loader';

const Landing = props => {
    const { onSetParams } = useContext(ParamsContext);
    const { device } = useContext(DeviceContext);
    const [loading, setLoader] = useState(true);
    const [slug, setSlug] = useState(null);
    const [paramsString, setParamsString] = useState(null);

    useEffect(() => {
      const paramsString = props.location.search;
      const paramsObj = new URLSearchParams(paramsString);
      const checkSlParam = () => {
        if (paramsObj.has('sl')) {
          setSlug(paramsObj.get('sl'));
        } else {
          setParamsString(paramsString)
        }
      }
      checkSlParam();
    }, [props.location.search]);

    useEffect(() => {
      const getLongUrl = async() => {
        try {
          const res = await axios.get(`/shortlink?slug=${slug}`, {
            headers: {
                'x-device-uuid': `${device.uuid}`
            }
          });
          if (res && res.data) {
            const paramStr = '?'+res.data.url.split('?')[1];
            setParamsString(paramStr);
          }
        } catch(err) {
          setLoader(false)
          props.history.push('/error');
        }
      }
      if (slug && slug !== null) {
        getLongUrl();
      }
    }, [slug, device, props.history]);

    useEffect(() => {
      const params = new URLSearchParams(paramsString);
        const checkParams = () => {
            let paramsToUpdate = {
              triggerable_id: null,
              triggerable_type: null,
              client_id: null,
              tracking_enabled: true,
              view: null,
              shareable: false,
              device_uuid: null,
              guid: null,
              content_id: null,
              fallback: false,
              trigger_uuid : null
            };
            params.forEach((param, key) => {
              if (paramsToUpdate.hasOwnProperty(key)) {
                if (key === 'tracking_enabled' || key === 'fallback') {
                  paramsToUpdate[key] = Number(param) === 1;
                } else {
                  paramsToUpdate[key] = param
                }
              }
            });
            onSetParams(paramsToUpdate);
            setLoader(false);
          }
          if (paramsString && paramsString !== null) {
            checkParams();
          }
          if (paramsString === '') {
            props.history.push({
              pathname: '/completed',
              state: {
                  title: 'YOU MISSED',
                  secondTitle: 'THE LIVE CONTEST',
                  description: 'You can still play and answer, and if you guess it right, you earn SuperFan points and one you have enough SuperFan points you will win SuperFan prizes! Tap below to get started.',
                  url: 'https://youbetyourlife.com/',
                  height: '267px',
                  urlText: 'YouBetYourLife.com',
                  hasAction: true
              }
          });
        }
          // eslint-disable-next-line
    }, [paramsString]);

    return (
      <Fragment>
          { loading ? <Loader /> : <Redirect to="/quiz" /> }
      </Fragment>
    );
}

export default withRouter(Landing);
