import React, {useState, useContext, useEffect, Fragment} from 'react';
import classes from './EmailSubmit.module.scss';
import { Link, withRouter } from 'react-router-dom';
import ResponseContext from '../../store/response-context';
import axios from '../../utils/axiosInstance';
import DeviceContext from '../../store/device-context';
import Hero from '../../components/UI/Hero/Hero';
import CommonCardPage from '../../components/CommonCardPage/CommonCardPage';
import Retry from '../Retry/Retry';

const EmailSubmit = props => {
    const { history } = props;
    const [enteredEmail, setEnteredEmail] = useState('');
    const [enteredFirstName, setEnteredFirstName] = useState('');
    const [enteredLastName, setEnteredLastName] = useState('');
    const [enteredPhoneNumber, setEnteredPhoneNumber] = useState('');
    const [formSubmit, setFormSubmit] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(null);
    const [alreadySubscribed, setAlreadySubscribed] = useState(null);
    const [showEmailError, setShowEmailError] = useState({
        value: false,
        retryFunc: null
    });
    const { response } = useContext(ResponseContext);
    const { device } = useContext(DeviceContext);

    useEffect(() => {
        if (response.responseId === "" || !response.winner || response.winner === null) {
            props.history.push({
                pathname: '/completed',
                state: {
                    title: 'YOU MISSED',
                    secondTitle: 'THE LIVE CONTEST',
                    description: 'You can still play and answer, and if you guess it right, you earn SuperFan points and one you have enough SuperFan points you will win SuperFan prizes! Tap below to get started.',
                    url: 'https://youbetyourlife.com/',
                    height: '267px',
                    urlText: 'YouBetYourLife.com',
                    hasAction: true
                }
            });
        }
    }, [response, props.history])

    useEffect(() => {
        const onPostEmailSubscription = async() => {
            try {
                const res = await axios.post(`user/subscribe`, {
                    source_type: 'question_answer',
                    source_id: response.responseId,
                    email: enteredEmail,
                    first_name: enteredFirstName,
                    last_name: enteredLastName,
                    phone_number: enteredPhoneNumber
                }, {
                    headers: {'x-device-uuid': `${device.uuid}`}
                });
                if (res) {
                    if (res.status === 201) {
                        setSubmitSuccess(true);
                    }
                    if (res.status === 200) {
                        setAlreadySubscribed(true);
                    }
                }
            } catch(err) {
                setShowEmailError({
                    value: true,
                    retryFunc: onPostEmailSubscription
                })
            }
        }
        if (formSubmit) {
            onPostEmailSubscription();
        }
    }, [formSubmit, enteredEmail, response, device, history, enteredFirstName, enteredLastName, enteredPhoneNumber, props.history])

    const emailSubmitHandler = (e) => {
        e.preventDefault();
        setFormSubmit(true);
    }

    const handleFoxNavigate = (e) => {
        e.preventDefault();
        window.open('https://youbetyourlife.com/')
    }
    const handleRetry = () => {
        showEmailError.retryFunc.call();
        setShowEmailError({
            value: false,
            retryFunc: null
        });
    }
    const emailSubmitForm = (
        <form id="submit-form" className={classes.FormContainer} onSubmit={emailSubmitHandler}>

            <div className={classes.FormInputContainer}>
                <div>
                    <label htmlFor="firstName">first name</label>
                </div>
                <input
                    required
                    autoComplete="true"
                    name="firstName"
                    type="text"
                    placeholder="John"
                    className={classes.EmailInput}
                    onChange={event => setEnteredFirstName(event.target.value)} />
            </div>

            <div className={classes.FormInputContainer}>
                <div>
                    <label htmlFor="lastName">last name</label>
                </div>
                <input
                    name="lastName"
                    required
                    autoComplete="true"
                    type="text"
                    placeholder="Smith"
                    className={classes.EmailInput}
                    onChange={event => setEnteredLastName(event.target.value)} />
            </div>

            <div className={classes.FormInputContainer}>
                <div>
                    <label htmlFor="email">email</label>
                </div>
                <input
                    name="email"
                    required
                    autoComplete="true"
                    type="email"
                    placeholder="name@domain.com"
                    className={classes.EmailInput}
                    onChange={event => setEnteredEmail(event.target.value)} />
            </div>

            <div className={classes.FormInputContainer}>
                <div>
                    <label htmlFor="phone">phone number</label>
                </div>
                <input
                    name="phone"
                    autoComplete="true"
                    required
                    type="tel"
                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                    placeholder="000000000"
                    className={classes.EmailInput}
                    onChange={event => setEnteredPhoneNumber(event.target.value)} />

            </div>

            <div className={classes.SweepstakesContainer}>
                <input
                    required
                    className={classes.SweepstakesCheck}
                    type="checkbox"
                    id="sweepstakes-rules" />

                <label htmlFor="sweepstakes-rules" className={classes.CheckboxLabel}>
                    By submitting your email, you agree to the <a href="https://youbetyourlife.com/terms/">Terms of Service</a>, <a href="https://youbetyourlife.com/privacy-policy/">Privacy Policy</a>, and <a href="https://youbetyourlife.com/qr-rules">Sweepstakes Rules.</a>
                </label>

            </div>

        </form>
    );

    const emailSubmitFormContainer = (
            <CommonCardPage
                cardHeader={'YOU GOT IT RIGHT'}
                height={'595px'}
                hasAction={true}
                actionText={'SUBMIT ENTRY'}
                type={'submit'}>
                <div className={classes.FormSubmitDescription}>
                    <p>Congratulations! You guessed it right for today’s <a href="https://youbetyourlife.com">You Bet Your Life</a> at Home game. Please provide your information below to enter for your chance to win today's prize.</p>
                    <p>If you are selected as a winner, we will contact you the email provided.</p>
                </div>
                {emailSubmitForm}
            </CommonCardPage>
    );

    const submitSuccessMessage = (
        <CommonCardPage
            cardHeader={'EMAIL SUBMITTED'}
            height={'267px'}
            hasAction={true}
            actionText={'YouBetYourLife.com'}
            url={'https://youbetyourlife.com/'}>
            <div className={classes.ThankYou}>
                <p>
                Thanks for playing <a href="https://youbetyourlife.com">You Bet Your Life</a> at home. We’ve received your entry for today's game.
                </p>
                <p>
                Tune in every weekday for your chance to play You Bet Your Life at home!
                </p>
            </div>
        </CommonCardPage>
    );

    const alreadyEntered = (
        <div className="common-container" style={{width: '100%'}}>
            <Hero />
            <div className={classes.SubmitContainer}>
                <div>
                    <div className={classes.AlreadyEnteredTitle}>
                        You have already <br/>
                        entered this contest
                    </div>
                    <div style={{marginTop: '58px', height: '104px'}} className={classes.AlreadyEnteredDescription}>
                        It looks like you’ve already <br/>
                        entered this contest previously. <br/>
                        Only one entry per episode. <br/>
                        Thank you!
                    </div>
                    <div className={classes.FoxButtonContainer}>
                        <button onClick={handleFoxNavigate} className={classes.FoxButton}>YouBetYourLife.com</button>
                    </div>
                </div>
            </div>

        </div>
    );

    return (
        <Fragment>
            { !formSubmit && !submitSuccess && emailSubmitFormContainer }
            { formSubmit && submitSuccess && submitSuccessMessage }
            { formSubmit && alreadySubscribed && alreadyEntered }
            { formSubmit && showEmailError.value === true && <Retry onClick={handleRetry}/> }
        </Fragment>
    );
}

export default withRouter(EmailSubmit);
