import React from 'react';
import classes from './Loader.module.scss';
import CountDown from '../CountDown/CountDown';

const Loader = props => {
    const { loadingMessage } = props;
    return (
        <div className="common-container" style={{position: 'relative'}}>
            <CountDown marginTop={'90px'} marginBottom={'30px'} />
            <div className={classes.LoadingText}>
                Loading...
            </div>
            <div className={classes.LoadingSubText}>
                { loadingMessage }
            </div>
        </div>
    );
}

export default Loader;
