import React from 'react';
import lights from '../../../images/V9hvNFF9-overlay-lights.svg';

const SpotLights = props => {
    return (
        <div className="noselect" style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            overflow:'hidden',
            objectFit: 'cover',
            zIndex: '-1',
            top: 0
        }}>
            <img src={lights} alt={lights} />
        </div>
    );
}

export default SpotLights;
