import React from 'react';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/CardHeader/CardHeader';

const HowToPlay = () => {
return (
<div className="common-container">
    <div style={{marginTop: '1px', width: '100%'}}>
        <Card>
            <div className="common-card-content">
                <CardHeader type={'common'} header={'HOW TO PLAY'} secondHeader={'AT HOME'} />
                <p className="p1"><span className="s1">Tune in to each episode of You Bet Your Life with Jay Leno and play the
                        daily quiz game for a chance to win!</span></p>
                <p className="p3"><span className="s1"><b>Scan the QR Code</b></span></p>
                <p className="p1"><span className="s1">When you see the QR Code appear on screen, grab your mobile device and
                        scan the QR Code with your camera app.<span className="Apple-converted-space">&nbsp;</span></span>
                </p>
                <p className="p1"><span className="s1"><i>Please note: Most updated phones can detect QR Codes from the regular
                            camera app, but that may be different for older devices.<span
                                className="Apple-converted-space">&nbsp;</span></i></span></p>
                <p className="p3"><span className="s1"><b>Playing the Game</b></span></p>
                <p className="p1"><span className="s1">Once you scan the QR Code, you’ll be taken to the You Bet Your Life quiz
                        game webpage.<span className="Apple-converted-space">&nbsp;</span></span></p>
                <p className="p1"><span className="s1">Tap ‘Let’s Play’ to get started.<span
                            className="Apple-converted-space">&nbsp;</span></span></p>
                <p className="p1"><span className="s1">After a moment, you see a quiz appear, and will have 10 seconds to
                        answer!</span></p>
                <p className="p1"><span className="s1">Tap to select your guess. Once the timer reacher zero, the answer will be
                        submitted.<span className="Apple-converted-space">&nbsp;</span></span></p>
                <p className="p3"><span className="s1"><b>Did you get it right or wrong?</b></span></p>
                <p className="p1"><span className="s1">If you answered the question correctly, you’ll be able to enter the You
                        Bet Your Life sweepstakes!<span className="Apple-converted-space">&nbsp;</span></span></p>
                <p className="p1"><span className="s1">Don’t worry if you didn’t get it right this time, you can try again once
                        every episode.</span></p>
                <p className="p1"><span className="s1">Good Luck!</span></p>
            </div>
        </Card>
    </div>
</div>
);
}

export default HowToPlay;