import React from 'react';

const Title = props => {
    return (
        <div
            style={{
                fontSize: props.fontSize,
                color: props.color,
                fontWeight: props.fontWeight,
                lineHeight: props.lineHeight ? props.lineHeight : props.fontSize,
                textTransform: 'uppercase',
                fontFamily: props.fontFamily
            }}>
            {props.children}
        </div>
    );
}

export default Title;
