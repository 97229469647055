import React from 'react';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/CardHeader/CardHeader';

const Terms = () => {
return (
<div className="common-container">
    <div style={{marginTop: '1px', width: '100%'}}>
        <Card>
            <div className="common-card-content">
                <CardHeader type={'common'} header={'TERMS &'} secondHeader={'CONDITIONS'} />
                <p className="p1"><span className="s1"><b>Description of Company Services and Acceptance of Terms of Use
                            Including Arbitration of Disputes</b></span></p>
                <p className="p1"><span className="s1"><b>Welcome to&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;(the
                            “Site”), which is operated by Otter Creek Productions, LLC (“Company,” “we” or “us”). The
                            services Company provides on&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;may
                            include video services, community pages and message boards, sweepstakes, contests and
                            promotions, mobile services, texting, email, push alert services, and any other features,
                            content, or applications offered from time to time by Company that link to these Terms of
                            Service in connection with Company’s business (collectively, the ” “the Company Services”).
                            Otter Creek Productions, LLC, is based in the United States and Company Services are hosted
                            in the United States. Otter Creek Productions, LLC, is a part of the United States based
                            operations of Fox Corporation.</b></span></p>
                <p className="p1"><span className="s1"><b>Otter Creek Productions, LLC, furnishes&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;and
                            the “the Company Services” for your personal enjoyment and entertainment. By
                            visiting&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;(whether
                            or not you are a registered member) or using the Company Services, you accept and agree to
                            be bound by this Agreement, including any future modifications (“Agreement”), and to abide
                            by all applicable laws, rules and regulations (“Applicable Law”). Please read through this
                            Agreement carefully. Otter Creek Productions, LLC, may modify this Agreement at any time,
                            and each such modification will be effective upon posting on&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>.
                            All material modifications will apply prospectively only. Your continued use of&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;or
                            the Company Services following any modification of this Agreement constitutes your
                            acceptance of and agreement to be bound by the Agreement as modified. It is therefore
                            important that you review this Agreement regularly. If you do not agree to be bound by this
                            Agreement and to abide by all Applicable Law, you must discontinue use of the Company
                            Services immediately.</b></span></p>
                <p className="p1"><span className="s1"><b>Your access to and use of certain Company Services may require you to
                            accept additional terms and conditions applicable to such Company Services, in addition to
                            this Agreement, and may require you to download software or Content (as defined below). In
                            the event of a conflict between any such additional terms and this Agreement, such
                            additional terms will prevail.</b></span></p>
                <p className="p1"><span className="s1"><b>PLEASE NOTE THAT THE “ARBITRATION AGREEMENT” SECTION BELOW CONTAINS
                            PROVISIONS THAT REQUIRE (i) WITH LIMITED EXCEPTIONS, ALL DISPUTES ARISING BETWEEN YOU AND
                            OTTER CREEK PRODUCTIONS, LLC, UNDER THIS AGREEMENT TO BE RESOLVED IN BINDING ARBITRATION,
                            AND NOT IN COURT, AND (ii) YOU AND OTTER CREEK PRODUCTIONS, LLC, WAIVE THE RIGHT TO BRING OR
                            PARTICIPATE IN A CLASS ACTION IN CONNECTION WITH SUCH DISPUTES.&nbsp;</b><a
                            href="https://youbetyourlife.com/terms/#bookmark-arbitration"><b>PLEASE CLICK HERE TO REVIEW
                                THE ARBITRATION AGREEMENT</b></a><b>. BY USING THIS SITE AND ACCEPTING THIS AGREEMENT,
                            YOU AGREE TO BE BOUND BY THE ARBITRATION AGREEMENT. PLEASE READ IT CAREFULLY.</b></span></p>
                <p className="p1"><span className="s1"><b>Registration and Security</b></span></p>
                <p className="p1"><span className="s1"><b>You take full responsibility for your participation on the Site. As a
                            condition of using certain features of the Site, you may be required to register on the Site
                            and/or select a username and password. All registration information you submit to create an
                            account must be accurate and kept up to date. Your failure to do so will constitute a breach
                            of the Agreement, which may result in immediate termination of your account. You may not (i)
                            select or use as a username a name of another person with the intent to impersonate that
                            person; or (ii) use as a username a name subject to any rights of another person without
                            appropriate authorization. Otter Creek Productions, LLC, reserves the right to refuse
                            registration of, or cancel, a username, in its sole discretion. It is your responsibility to
                            notify us of any changes in such information, including but not limited to your contact
                            information.</b></span></p>
                <p className="p1"><span className="s1"><b>You are responsible for maintaining the confidentiality of your
                            password and are responsible for all use of your account. It is therefore critical that you
                            do not share your password with anyone. You agree not to use the account, username, email
                            address or password of another [member or subscriber] at any time and not to allow any other
                            person to use your account. You agree to sign out of your account each time you conclude a
                            visit to&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>.
                            Your account is not transferable. You agree to notify Otter Creek Productions, LLC,
                            immediately if you suspect any unauthorized use of, or access to, your account or
                            password.</b></span></p>
                <p className="p1"><span className="s1"><b>Access</b></span></p>
                <p className="p1"><span className="s1"><b>The Site is intended solely for your personal and non-commercial use.
                            Otter Creek Productions, LLC, may change, suspend or discontinue the Site (or any feature
                            thereof) at any time. Otter Creek Productions, LLC, may also impose limits on certain
                            features and services offered on the Site or restrict your access to parts or all of the
                            Site without notice or liability. You acknowledge that from time to time the Site may be
                            inaccessible or inoperable for any reason, including, without limitation: (i) equipment
                            malfunctions; (ii) periodic maintenance procedures or repairs which Otter Creek Productions,
                            LLC, may undertake from time to time; or (iii) causes beyond the control of Otter Creek
                            Productions, LLC, or that are not reasonably foreseeable by Otter Creek Productions,
                            LLC.</b></span></p>
                <p className="p1"><span className="s1"><b>Limited Content License</b></span></p>
                <p className="p1"><span className="s1"><b>Company Services are offered for your personal use only and may not be
                            used for commercial purposes. Company Services contain information, text, files, images,
                            videos, sounds, musical works, works of authorship, software, applications, product names,
                            Otter Creek Productions, LLC, names, trade names, logos, designs, and any other materials or
                            content (collectively, “Content”) of Otter Creek Productions, LLC, its licensors, or
                            assignors (“Otter Creek Productions, LLC, Content”), as well as Content provided by users or
                            other third parties. Content contained in the Company Services is protected by copyright,
                            trademark, patent, trade secret and other laws and, as between you and Otter Creek
                            Productions, LLC, Otter Creek Productions, LLC, its licensors, or its assignors, own and
                            retain all rights in the Otter Creek Productions, LLC Content. Otter Creek Productions, LLC,
                            hereby grants you a limited, revocable, nonsublicensable license to access and display or
                            perform the Otter Creek Productions, LLC, Content (excluding any software code) solely for
                            your personal, non-commercial use in connection with using the Company Services. Except as
                            provided in this Agreement or as explicitly allowed on the Company Services, you may not
                            copy, download, stream, capture, reproduce, duplicate, archive, upload, modify, translate,
                            publish, broadcast, transmit, retransmit, distribute, perform, display, sell, frame or
                            deep-link, make available, or otherwise use any Content contained in the Company
                            Services.</b></span></p>
                <p className="p1"><span className="s1"><b>Except as explicitly and expressly permitted by the Otter Creek
                            Productions, LLC, or by the limited license set forth above, you are strictly prohibited
                            from creating works or materials (including but not limited to fonts, icons, link buttons,
                            wallpaper, desktop themes, on-line postcards, montages, mash-ups and similar videos,
                            greeting cards and unlicensed merchandise) that derive from or are based on the Otter Creek
                            Productions, LLC, Content. This prohibition applies regardless of whether such derivative
                            works or materials are sold, bartered or given away. Also, you may not either directly or
                            through the use of any device, software, internet site, web-based service or other means,
                            remove, alter, bypass, avoid, interfere with, or circumvent any copyright, trademark, or
                            other proprietary notice marked on the Content contained in the Company Services or any
                            digital rights management mechanism, device, or other content protection, copy control or
                            access control measure associated with the Content contained in Company Services, including
                            geo-filtering mechanisms. Except as necessary in order to make reference to Otter Creek
                            Productions, LLC, its products and services in a purely descriptive capacity, you are
                            expressly prohibited from using any Otter Creek Productions, LLC, Content in any
                            manner.</b></span></p>
                <p className="p1"><span className="s1"><b>You may not, without the Company’s written permission, “mirror” any
                            Contents contained on the Site or any other server. You may not use the Site for any purpose
                            that is unlawful or prohibited by the Agreement. You may not use the Site in any manner that
                            could damage, disable, overburden, or impair the Site, or interfere with any other party’s
                            use and enjoyment of the Site. You may not attempt to gain unauthorized access to the Site
                            through hacking, password mining or any other means. Otter Creek Productions, LLC, reserves
                            the right, in its sole discretion, to terminate your access to the Site, or any portion
                            thereof, at any time, for any reason or for no reason at all, without prior notice or any
                            notice.</b></span></p>
                <p className="p1"><span className="s1"><b>Restrictions on Use of Company Services</b></span></p>
                <p className="p1"><span className="s1"><b>You understand that you are responsible for all Content that you post,
                            upload, transmit, email or otherwise make available on&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;or
                            on, through or in connection with the Company Services (collectively, “User Content”).
                            Additionally, you acknowledge that you have no expectation of privacy in or confidentiality
                            with respect to your User Content. Accordingly, please choose User Content
                            carefully.</b></span></p>
                <p className="p1"><span className="s1"><b>You agree not to use the Company Services to:</b></span></p>
                <ul className="ul1">
                    <li className="li2"><span className="s3"><b>Post, upload or otherwise transmit or link to Content that is:
                                unlawful; threatening; abusive; obscene; vulgar; sexually explicit; pornographic or
                                inclusive of nudity; offensive; excessively violent; invasive of another’s privacy,
                                publicity, contract or other rights; tortious; false or misleading; defamatory;
                                libelous; hateful; or discriminatory;</b></span></li>
                    <li className="li2"><span className="s3"><b>Violate the rights of others including patent, trademark, trade
                                secret, copyright, privacy, publicity or other proprietary rights;</b></span></li>
                    <li className="li2"><span className="s3"><b>Harass or harm another person;</b></span></li>
                    <li className="li2"><span className="s3"><b>Exploit or endanger a minor;</b></span></li>
                    <li className="li2"><span className="s3"><b>Impersonate or attempt to impersonate any person or
                                entity;</b></span></li>
                    <li className="li2"><span className="s3"><b>Introduce or engage in activity that involves the use of
                                viruses, bots, worms, or any other computer code, files or programs that interrupt,
                                destroy, or limit the functionality of any computer software or hardware or
                                telecommunications equipment, or otherwise permit the unauthorized use of or access to a
                                computer or a computer network;</b></span></li>
                    <li className="li2"><span className="s3"><b>Attempt to decipher, decompile, disassemble or reverse engineer
                                any of the software comprising&nbsp;</b><a
                                href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><span
                                    className="s1"><b>youbetyourlife.com</b></span></a><b>&nbsp;or the Company
                                Services;</b></span></li>
                    <li className="li2"><span className="s3"><b>Interfere with, damage, disable, disrupt, impair, create an
                                undue burden on, or gain unauthorized access to the Company Services, including Otter
                                Creek Productions, LLC’s, servers, networks or accounts;</b></span></li>
                    <li className="li2"><span className="s3"><b>Cover, remove, disable, block or obscure advertisements or other
                                portions of the Company Services;</b></span></li>
                    <li className="li2"><span className="s3"><b>Delete or revise any information provided by or pertaining to
                                any other user of the Company Services;</b></span></li>
                    <li className="li2"><span className="s3"><b>Use technology or any automated system such as scripts, spiders,
                                offline readers or bots in order to collect or disseminate usernames, passwords, email
                                addresses or other data from the Company Services, or to circumvent or modify any
                                security technology or software that is part of the Company Services;</b></span></li>
                    <li className="li2"><span className="s3"><b>Send or cause to send (directly or indirectly) unsolicited bulk
                                messages or other unsolicited bulk communications of any kind through the Company
                                Services. If you do so, you acknowledge you will have caused substantial harm to Otter
                                Creek Productions, LLC, but that the amount of harm would be extremely difficult to
                                measure. As a reasonable estimation of such harm, and by way of liquidated damages and
                                not as a penalty, you agree to pay Otter Creek Productions, LLC, $50 for each actual or
                                intended recipient of such communication;</b></span></li>
                    <li className="li2"><span className="s3"><b>Solicit, collect or request any personal information for
                                commercial or unlawful purposes;</b></span></li>
                    <li className="li2"><span className="s3"><b>Post, upload or otherwise transmit an image or video of another
                                person without that person’s consent;</b></span></li>
                    <li className="li2"><span className="s3"><b>Engage in commercial activity (including but not limited to
                                advertisements or solicitations of business; sales; contests; sweepstakes; creating,
                                recreating, distributing or advertising an index of any significant portion of the Otter
                                Creek Productions, LLC, Content; or building a business using the Otter Creek
                                Productions, LLC, Content) without Otter Creek Productions, LLC’s, prior written
                                consent;</b></span></li>
                    <li className="li2"><span className="s3"><b>Use the Company Services to advertise or promote competing
                                services;</b></span></li>
                    <li className="li2"><span className="s3"><b>Use the Company Services in a manner inconsistent with any and
                                all Applicable Law;</b></span></li>
                    <li className="li2"><span className="s3"><b>Attempt, facilitate, induce, aid and abet, or encourage others
                                to do any of the foregoing.</b></span></li>
                </ul>
                <p className="p1"><span className="s1"><b>Otter Creek Productions, LLC, reserves the right, but disclaims any
                            obligation or responsibility, to remove User Content that violates this Agreement, as
                            determined by Otter Creek Productions, LLC, or for any other reason, in Otter Creek
                            Productions, LLC’s, sole discretion and without notice to you. You acknowledge the Otter
                            Creek Productions, LLC, reserves the right to investigate and take appropriate legal action
                            against anyone who, in Otter Creek Productions, LLC’s, sole discretion, violates this
                            Agreement, including but not limited to, terminating their user account and/or reporting
                            such User Content, conduct, or activity to law enforcement authorities.</b></span></p>
                <p className="p1"><span className="s1"><b>You acknowledge, consent and agree that Otter Creek Productions, LLC,
                            may access, preserve or disclose information you provide to&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>,
                            including User Content and your account registration information, including when Otter Creek
                            Productions, LLC, has a good faith belief that such access, preservation or disclosure is
                            necessary in order to: (i) protect, enforce, or defend the legal rights, privacy, safety, or
                            property of Otter Creek Productions, LLC, our parents, subsidiaries or affiliates (“Otter
                            Creek Productions, LLC, Affiliates”), or their employees, agents and contractors (including
                            enforcement of this Agreement or our other agreements); (ii) protect the safety, privacy,
                            and security of users of the Company Services or members of the public including in urgent
                            circumstances; (iii) protect against fraud or for risk management purposes; (iv) comply with
                            the law or legal process; or (v) respond to requests from public and government authorities.
                            If Otter Creek Productions, LLC, sells all or part of its business or makes a sale or
                            transfer of its assets or is otherwise involved in a merger or transfer of all or a material
                            part of its business, Otter Creek Productions, LLC, may transfer your information to the
                            party or parties involved in the transaction as part of that transaction.</b></span></p>
                <p className="p1"><span className="s1"><b>Otter Creek Productions, LLC, reserves the right to limit the storage
                            capacity of User Content. You assume full responsibility for maintaining backup copies of
                            your User Content, and Otter Creek Productions, LLC, assumes no responsibility for any loss
                            of your User Content due to its being removed by Otter Creek Productions, LLC, or for any
                            other reason.</b></span></p>
                <p className="p1"><span className="s1"><b>User Content on Message Boards and Forums</b></span></p>
                <p className="p1"><span className="s1"><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;offers
                            users the ability to post messages on message boards and forums (collectively, “Forums”),
                            which may be open to the public generally, to all members of&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>,
                            or to a select group of members to a specific Forum group. You acknowledge that all Content
                            posted on Forums is User Content, and by posting on Forums you agree to comply with the
                            rules and restrictions on User Content set forth above and any other rules specifically
                            applicable to such Forums. Otter Creek Productions, LLC, reserves the right, but disclaims
                            any obligation or responsibility, to prevent you from posting User Content to any Forum and
                            to restrict or remove your User Content from a Forum or refuse to include your User Content
                            in a Forum for any reason at any time, in Otter Creek Productions, LLC’s, sole discretion
                            and without notice to you.</b></span></p>
                <p className="p1"><span className="s1"><b>You acknowledge that messages posted on such Forums are public, and
                            Otter Creek Productions, LLC, cannot guarantee the security of any information you disclose
                            through any Forum; you make such disclosures at your own risk. Otter Creek Productions, LLC,
                            is not responsible for the content or accuracy of any information posted on a Forum, and
                            shall not be responsible for any decisions made based on such information.</b></span></p>
                <p className="p1"><span className="s1"><b>Your Proprietary Rights in and License to Your User Content</b></span>
                </p>
                <p className="p1"><span className="s1"><b>Otter Creek Productions, LLC, does not claim any ownership rights in
                            the User Content that you post, upload, email, transmit, or otherwise make available
                            (collectively, “Transmit”) on, through or in connection with the Company Services, except
                            with respect to your unsolicited submissions, as described under “Unsolicited Submissions”
                            below; provided, however, that User Content shall not include any Otter Creek Productions,
                            LLC, Content or content owned by an Otter Creek Productions, LLC, Affiliate. By posting or
                            transmitting any User Content on, through or in connection with the Company Services, you
                            hereby grant to Otter Creek Productions, LLC, and our Otter Creek Productions, LLC,
                            Affiliates, licensees, assignees, and authorized users a worldwide, perpetual, irrevocable,
                            non-exclusive, fully-paid and royalty-free, freely sublicensable, transferable (in whole or
                            in part) right (including any moral rights) and license to use, modify, excerpt, adapt,
                            publish, translate, create derivative works and compilations based upon, publicly perform,
                            publicly display, reproduce, sublicense, and distribute such User Content, including your
                            name, voice, likeness and other personally identifiable information to the extent that such
                            is contained in User Content, anywhere, in any form and on and through all media formats now
                            known or hereafter devised, for any and all purposes including, but not limited to,
                            promotional, marketing, trade or any non-commercial or commercial purposes. Additionally,
                            Otter Creek Productions, LLC, is free to use any ideas, concepts, know-how, or techniques
                            contained within such User Content for any purpose including, but not limited to,
                            developing, manufacturing, marketing and providing commercial products and services,
                            including Company Services. Otter Creek Productions, LLC‘s, use of such User Content shall
                            not require any further notice or attribution to you and such use shall be without the
                            requirement of any permission from or any payment to you or any other person or entity. You
                            hereby appoint Otter Creek Productions, LLC, as your agent with full authority to execute
                            any document or take any action Otter Creek Productions, LLC, may consider appropriate in
                            order to confirm the rights granted by you to Otter Creek Productions, LLC, in this
                            Agreement.</b></span></p>
                <p className="p1"><span className="s1"><b>You represent and warrant that: (i) you own the User Content
                            Transmitted by you on, through or in connection with the Company Services, or otherwise have
                            the right to grant the license set forth in this Section, and (ii) the Transmission of User
                            Content by you on, through or in connection with the Company Services and Third Party
                            Services does not violate the privacy rights, publicity rights, copyrights, contract rights
                            or any other rights of any person or entity. You agree to pay for all royalties, fees, and
                            any other monies owing any person or entity by reason of the use of any User Content
                            Transmitted by you on or through the Company Services or Third Party Services.</b></span>
                </p>
                <p className="p1"><span className="s1"><b>If you delete your User Content from&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>,
                            Otter Creek Productions, LLC’s, license to such User Content will end after a reasonable
                            period of time necessary for the deletion to take full effect. However, the User Content may
                            be retained in the Otter Creek Productions, LLC’s, back-up copies of&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>,
                            which are not publicly available. Furthermore, to the extent that Otter Creek Productions,
                            LLC, made use of your User Content before you deleted it, Otter Creek Productions, LLC, will
                            retain the right to make such pre-existing uses even after your User Content is deleted. You
                            acknowledge that (i) deletion of your User Content from&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;will
                            not result in, and Otter Creek Productions, LLC, assumes no responsibility for, the deletion
                            of such User Content by any third parties who were provided with or had access to such User
                            Content prior to your deleting it from&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>,
                            and (ii) termination of your account or your use of the Company Services will not result in
                            the immediate or automatic deletion of your User Content consistent with this
                            Agreement.</b></span></p>
                <p className="p1"><span className="s1"><b>Removal of Material that Infringes Copyrights</b></span></p>
                <p className="p1"><span className="s1"><b>Otter Creek Productions, LLC, respects the intellectual property of
                            others and requires that our users do the same. Otter Creek Productions, LLC, has a policy
                            that provides for the termination in appropriate circumstances of subscribers and account
                            holders of Company Services who are repeat infringers. Otter Creek Productions, LLC, also
                            reserves the right to remove or disable access to any transmission of Content that infringes
                            the copyright of any person under the laws of the United States upon receipt of a notice
                            that substantially complies with the requirements of 17 U.S.C. § 512(c)(3) as set forth
                            above.</b></span></p>
                <p className="p1"><span className="s1"><b>If you believe material on Company Services infringes your
                            copyright.</b></span></p>
                <p className="p1"><span className="s1"><b>If you believe that any material residing on or linked to Company
                            Services infringes your copyright, you must send Otter Creek Productions, LLC’s, designated
                            Copyright Agent a written notification of claimed infringement that contains substantially
                            all of the following information: (a) identification of the copyrighted work claimed to have
                            been infringed, or, if multiple copyrighted works are covered by a single notification, a
                            representative list of such works; (b) identification of the claimed infringing material and
                            information reasonably sufficient to permit us to locate the material on the Company
                            Services (such as the URL(s) of the claimed infringing material); (c) information reasonably
                            sufficient to permit us to contact you, such as an address, telephone number, and an email
                            address; (d) a statement by you that you have a good faith belief that the disputed use is
                            not authorized by the copyright owner, its agent, or the law; (e) a statement by you that
                            the above information in your notification is accurate and a statement by you, made under
                            penalty of perjury, that you are the owner of an exclusive right that is allegedly infringed
                            or are authorized to act on the owner’s behalf; and (f) your physical or electronic
                            signature. Company’s Copyright Agent for notification of claimed infringement can be reached
                            as follows:</b></span></p>
                <p className="p3"><span className="s3"><b>Otter Creek Productions Copyright Agent, c/o Fox Corporation, 2121
                            Avenue of the Stars, Suite 700, Los Angeles, California 90067.</b></span><span
                        className="s1"><b><br />
                        </b></span><span className="s3"><b>Company’s Copyright Agent can also be reached electronically
                            at&nbsp;</b><a href="mailto:FOXDMCA@fox.com"><span
                                className="s1"><b>FOXDMCA@fox.com</b></span></a><b>.</b></span></p>
                <p className="p1"><span className="s1"><b>If you posted material to&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;that
                            was removed due to notice by a copyright owner.</b></span></p>
                <p className="p1"><span className="s1"><b>If you posted material to&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;that
                            Otter Creek Productions, LLC, removed due to a notice of claimed infringement from a
                            copyright owner, Otter Creek Productions, LLC, will take reasonable steps promptly to notify
                            you that the material has been removed or disabled. This notice may be by means of a general
                            notice on&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;or
                            by written or electronic communication to such address(es) you have provided to Otter Creek
                            Productions, LLC, if any. You may provide counter-notification in response to such notice in
                            a written communication that includes substantially all of the following: (i) identification
                            of the material that has been removed or to which access has been disabled and the location
                            at which the material appeared before it was removed or access to it was disabled; (ii) a
                            statement by you, under penalty of perjury, that you have a good faith belief that the
                            material was removed or disabled as a result of mistake or misidentification of the material
                            to be removed or disabled; (iii) your name, address, telephone number, and a statement that
                            you consent to the jurisdiction of the Federal District Court for the judicial district in
                            which your address is located, or if your address is outside of the United States, for any
                            judicial district in which Otter Creek Productions, LLC, may be found, and that you will
                            accept service of process from the person who provided notification requesting the removal
                            or disabling of access to the material or such person’s agent; and (iv) your physical or
                            electronic signature.</b></span></p>
                <p className="p1"><span className="s1"><b>Please note that, under 17 U.S.C. §512(f), any person who knowingly
                            makes material misrepresentations in a notification of claimed infringement or any
                            counter-notification may be liable for damages.</b></span></p>
                <p className="p1"><span className="s1"><b>Your Exposure to Others’ User Content</b></span></p>
                <p className="p1"><span className="s1"><b>You understand that Otter Creek Productions, LLC, does not control the
                            User Content posted by users via the Company Services and, as such, you understand you may
                            be exposed to offensive, inaccurate or otherwise objectionable User Content. Otter Creek
                            Productions, LLC, assumes no responsibility or liability for this type of Content. If you
                            become aware of any misuse of the Company Services, including in violation of any
                            “Restrictions on Use of Company Services,” please report it immediately to Otter Creek
                            Productions, LLC,&nbsp;</b><a
                            href="mailto:Privacy-FFR@fox.com"><b>Privacy-FFR@fox.com</b></a><b>. Otter Creek
                            Productions, LLC, assumes no responsibility for monitoring the Company Services for
                            inappropriate User Content or user conduct. If at any time, Otter Creek Productions, LLC,
                            chooses in its sole discretion to monitor the Company Services, Otter Creek Productions,
                            LLC, nonetheless assumes no responsibility for Content other than Otter Creek Productions,
                            LLC, Content, no obligation to modify or remove any inappropriate Content, and no
                            responsibility for the conduct of any user.</b></span></p>
                <p className="p1"><span className="s1"><b>Third Party Links and Services</b></span></p>
                <p className="p1"><span className="s1"><b>The Company Services may provide, or third parties may provide, links
                            to other websites, applications, resources or other services created by third parties
                            (“Third Party Services”). When you engage with a provider of a Third Party Service, you are
                            interacting with the third party, not with Otter Creek Productions, LLC. If you choose to
                            use a Third Party Service and share information with it, the provider of the Third Party
                            Service may use and share your data in accordance with its privacy policy and your privacy
                            settings on such Third Party Service. Otter Creek Productions, LLC, encourages you not to
                            provide any personally identifiable information to or through any Third Party Service unless
                            you know and are comfortable with the party with whom you are interacting. In addition, the
                            provider of the Third Party Service may use other parties to provide portions of the
                            application or service to you, such as technology, development or payment services. Otter
                            Creek Productions, LLC, is not responsible for and makes no warranties, express or implied,
                            as to the Third Party Services or the providers of such Third Party Services (including, but
                            not limited to, the accuracy or completeness of the information provided by such Third Party
                            Service or the privacy practices thereof). Inclusion of any Third Party Service or a link
                            thereto on the Company. Services does not imply approval or endorsement of the Third Party
                            Service. Otter Creek Productions, LLC, is not responsible for the content or practices of
                            any websites other than&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>,
                            even if the website links to&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;and
                            even if it is operated by a Company Affiliate or a Company otherwise connected
                            with&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>.
                            By using the Company Services, you acknowledge and agree that Otter Creek Productions, LLC,
                            is not responsible or liable to you for any content or other materials hosted and served
                            from any website other than&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>.
                            When you access Third Party Services, you do so at your own risk.</b></span></p>
                <p className="p1"><span className="s1"><b>Member Disputes</b></span></p>
                <p className="p1"><span className="s1"><b>You are solely responsible for your interactions with other users
                            of&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;and
                            the Company Services, providers of Third Party Services or any other parties with whom you
                            interact on, through or in connection with Company Services. Otter Creek Productions, LLC,
                            reserves the right, but has no obligation, to become involved in any way with any disputes
                            between you and such parties.</b></span></p>
                <p className="p1"><span className="s1"><b>Privacy</b></span></p>
                <p className="p1"><span className="s1"><b>Use of the Company Services is also governed by our&nbsp;</b><a
                            href="https://youbetyourlife.com/privacy-policy"><b>Privacy Policy</b></a><b>, which is
                            incorporated into and is a part of this Agreement by this reference.</b></span></p>
                <p className="p1"><span className="s1"><b>Disclaimers</b></span></p>
                <p className="p1"><span className="s1"><b>COMPANY SERVICES ARE PROVIDED “AS-IS” AND “AS AVAILABLE” AND OTTER
                            CREEK PRODUCTIONS, LLC, DOES NOT GUARANTEE OR PROMISE ANY SPECIFIC RESULTS FROM USE OF OR
                            CONTINUOUS AVAILABILITY OF THE COMPANY SERVICES. TO THE FULLEST EXTENT PERMITTED BY
                            APPLICABLE LAW, OTTER CREEK PRODUCTIONS, LLC, EXPRESSLY DISCLAIMS ANY WARRANTIES AND
                            CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE
                            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                            NON-INFRINGEMENT, AND WARRANTIES IMPLIED FOR A COURSE OF PERFORMANCE OR COURSE OF DEALING.
                            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, OTTER CREEK PRODUCTIONS, LLC, MAKES NO
                            WARRANTY THAT YOUR USE OF THE COMPANY SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
                            ERROR-FREE, THAT DEFECTS TO THE COMPANY SERVICES WILL BE CORRECTED, THAT THE COMPANY
                            SERVICES OR THE SERVERS ON WHICH THEY ARE AVAILABLE WILL BE FREE OF VIRUSES OR OTHER HARMFUL
                            COMPONENTS, OR THAT ANY INFORMATION OBTAINED BY YOU ON, THROUGH OR IN CONNECTION WITH THE
                            COMPANY SERVICES OR THIRD PARTY SERVICES (INCLUDING, BUT NOT LIMITED TO, THROUGH USER
                            CONTENT OR THIRD PARTY ADVERTISEMENTS) WILL BE ACCURATE, RELIABLE, TIMELY OR COMPLETE. UNDER
                            NO CIRCUMSTANCES WILL OTTER CREEK PRODUCTIONS, LLC, BE RESPONSIBLE FOR ANY LOSS OR DAMAGE
                            (INCLUDING BUT NOT LIMITED TO LOSS OF DATA, PROPERTY DAMAGE, PERSONAL INJURY OR DEATH)
                            RESULTING FROM USE OF THE COMPANY SERVICES, PROBLEMS OR TECHNICAL MALFUNCTION IN CONNECTION
                            WITH USE OF THE COMPANY SERVICES, ATTENDANCE AT A OTTER CREEK PRODUCTIONS, LLC, EVENT, ANY
                            MATERIAL DOWNLOADED OR OTHERWISE OBTAINED IN CONNECTION WITH THE COMPANY SERVICES, ANY USER
                            CONTENT, ANY THIRD PARTY ADVERTISEMENT OR THIRD PARTY SERVICE TRANSMITTED ON, THROUGH OR IN
                            CONNECTION WITH THE COMPANY SERVICES, OR THE CONDUCT OF ANY USERS OF THE COMPANY SERVICES,
                            WHETHER ONLINE OR OFFLINE. YOUR USE OF USER CONTENT, THIRD PARTY ADVERTISEMENTS, THIRD PARTY
                            SERVICES AND THE GOODS OR SERVICES PROVIDED BY ANY THIRD PARTIES IS SOLELY YOUR
                            RESPONSIBILITY AND AT YOUR OWN RISK.</b></span></p>
                <p className="p1"><span className="s1"><b>YOU ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE COMPANY SERVICES, AND
                            ANY INFORMATION TRANSMITTED OR RECEIVED IN CONNECTION THEREWITH, MAY NOT BE SECURE AND MAY
                            BE INTERCEPTED BY UNAUTHORIZED PARTIES. YOU ASSUME RESPONSIBILITY FOR THE ENTIRE COST OF ANY
                            MAINTENANCE, REPAIR OR CORRECTION TO YOUR COMPUTER SYSTEM OR OTHER PROPERTY OR RECOVERY OR
                            RECONSTRUCTION OF LOST DATA NECESSITATED BY YOUR USE OF THE COMPANY SERVICES.</b></span></p>
                <p className="p1"><span className="s1"><b>Limitation on Liability</b></span></p>
                <p className="p1"><span className="s1"><b>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, Otter Creek
                            Productions, LLC, WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT,
                            CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING, WITHOUT
                            LIMITATION, LOST PROFIT DAMAGES ARISING FROM YOUR USE OF OR INABILITY TO USE THE COMPANY
                            SERVICES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, TO THE FULLEST EXTENT
                            PERMITTED BY APPLICABLE LAW, OTTER CREEK PRODUCTIONS, LLC’S, LIABILITY TO YOU FOR ANY CAUSE
                            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE
                            AMOUNT PAID, IF ANY, BY YOU TO OTTER CREEK PRODUCTIONS, LLC, FOR THE COMPANY SERVICES DURING
                            THE TERM OF YOUR USE OF THE COMPANY SERVICES.</b></span></p>
                <p className="p1"><span className="s1"><b>YOU ACKNOWLEDGE AND AGREE THAT ANY DAMAGES YOU INCUR ARISING OUT OF
                            OTTER CREEK PRODUCTIONS, LLC’S, ACTS OR OMISSIONS OR YOUR USE OF&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;OR
                            THE COMPANY SERVICES ARE NOT IRREPARABLE AND ARE INSUFFICIENT TO ENTITLE YOU TO AN
                            INJUNCTION OR OTHER EQUITABLE RELIEF RESTRICTING THE AVAILABILITY OF OR ANY PERSON’S ABILITY
                            TO ACCESS ANY PORTION OF&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;OR
                            THE COMPANY SERVICES.</b></span></p>
                <p className="p1"><span className="s1"><b>THE LIMITATIONS IN THIS SECTION APPLY WHETHER THE ALLEGED LIABILITY IS
                            BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF OTTER
                            CREEK PRODUCTIONS, LLC, HAS BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES.</b></span>
                </p>
                <p className="p1"><span className="s1"><b>United States Jurisdiction</b></span></p>
                <p className="p1"><span className="s1"><b>Otter Creek Productions, LLC, provides the Company Services in the
                            United States of America. Otter Creek Productions, LLC, does not represent that the Otter
                            Creek Productions, LLC, Content or the Company Services are appropriate (or, in some cases,
                            available) for use in other locations. If you use&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;or
                            the Company Services from a jurisdiction other than the United States, you agree that you do
                            so of your own initiative, and you are responsible for complying with local laws as
                            applicable to your use of&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;or
                            the Company Services.</b></span></p>
                <p className="p1"><span className="s1"><b>Not all of the Site Products are available worldwide or nationwide,
                            and Otter Creek Productions, LLC, makes no representation that you will be able to obtain
                            any Site Product in any particular jurisdiction, either within or outside of the United
                            States.</b></span></p>
                <p className="p1"><span className="s1"><b>U.S. Export Controls</b></span></p>
                <p className="p1"><span className="s1"><b>Software available in connection with the Company Services is further
                            subject to United States export controls. No such software may be downloaded from the
                            Company Services or otherwise exported or re-exported in violation of U.S. export laws.
                            Downloading or using such software is at your sole risk.</b></span></p>
                <p className="p1"><span className="s1"><b>&nbsp;</b></span></p>
                <p className="p1"><span className="s1"><b>Arbitration Agreement</b></span></p>
                <p className="p1"><span className="s1"><b>(1) Otter Creek Productions, LLC, including its Otter Creek
                            Productions, LLC, Affiliates, agents, employees, predecessors in interest, successors, and
                            assigns, and you agree that any Dispute (as defined herein) between you and Otter Creek
                            Productions, LLC, regarding any aspect of your relationship with Otter Creek Productions,
                            LLC, will be resolved in a binding, confidential, individual and fair arbitration process,
                            and not in court. Each of you and Otter Creek Productions, LLC, agrees to give up the right
                            to sue in court. The term “Dispute” is to be given the broadest possible meaning that will
                            be enforced, and shall include any dispute, claim, demand, count, cause of action, or
                            controversy between you and Otter Creek Productions, LLC, whether based in contract,
                            statute, regulation, ordinance, tort (including, but not limited to, fraud,
                            misrepresentation, fraudulent inducement, negligence, or any other intentional tort), or any
                            other legal or equitable theory. The term “Dispute” specifically includes, but is not
                            limited to, any and all claims between you and Otter Creek Productions, LLC, in any way
                            related to or concerning this Arbitration Agreement, any other aspect of these Terms of Use
                            (including their applicability and their conformance to applicable law), any products or
                            services provided by Otter Creek Productions, LLC, any billing disputes, and any disputes
                            relating to telephonic, text message, or any other communications either of us received from
                            the other. The only exceptions to this Arbitration Agreement are that (i) each of you and
                            Otter Creek Productions, LLC, retains the right to sue in small claims court and (ii) each
                            of you and Otter Creek Productions, LLC, may bring suit in court against the other to enjoin
                            infringement or other misuse of intellectual property rights. Disputes over whether these
                            exceptions apply shall be resolved by the court in which such action has been brought; all
                            other disputes over arbitrability shall be resolved by the arbitrator. Each of you and Otter
                            Creek Productions, LLC, also agrees to give up the ability to seek to represent, in a class
                            action or otherwise, anyone but each of you and Otter Creek Productions, LLC (see paragraph
                            9 below). There is no judge or jury in arbitration, and court review of an arbitration award
                            is limited. An arbitrator must follow this Agreement. The arbitrator, however, can award on
                            an individual basis the same damages and relief as a court (including injunctive and
                            declaratory relief, or statutory damages).</b></span></p>
                <p className="p1"><span className="s1"><b>(2) This Agreement evidences a transaction in interstate commerce, and
                            thus the Federal Arbitration Act, 9 U.S.C. §§ 1-16, governs the interpretation and
                            enforcement of this Arbitration Agreement. This Arbitration Agreement shall survive
                            termination of this Agreement.</b></span></p>
                <p className="p1"><span className="s1"><b>(3) Any arbitration between you and Otter Creek Productions, LLC, will
                            be conducted by the Judicial Arbitration and Mediation Services, Inc. (“JAMS”), pursuant to
                            the JAMS Streamlined Arbitration Rules &amp; Procedures effective July 1, 2014 (the “JAMS
                            Rules”), as modified by this agreement to arbitrate. The arbitration shall be conducted by a
                            single, neutral arbitrator, and if you and Otter Creek Productions, LLC, cannot agree on who
                            that single arbitrator will be, the arbitrator will be appointed pursuant to the JAMS Rules,
                            with the participation and involvement of Otter Creek Productions, LLC, and you pursuant to
                            JAMS Rule 12. The JAMS Rules are available on its website at&nbsp;</b><a
                            href="http://www.jamsadr.com/rules-streamlined-arbitration/"><b>http://www.jamsadr.com/rules-streamlined-arbitration/</b></a><b>.
                            The Consumer Arbitration Minimum Standards are available at&nbsp;</b><a
                            href="http://www.jamsadr.com/consumer-arbitration/"><b>http://www.jamsadr.com/consumer-arbitration/</b></a><b>.
                            The arbitrator is bound by the terms of this Agreement.</b></span></p>
                <p className="p1"><span className="s1"><b>(4) If either you or Otter Creek Productions, LLC, wants to arbitrate
                            a claim, you or Otter Creek Productions, LLC, must first send by mail to the other a written
                            Notice of Dispute (“Notice”) that sets forth the name, address, and contact information of
                            the party giving notice, the specific facts giving rise to the Dispute, the Otter Creek
                            Productions, LLC, Service to which the Notice relates, and the relief requested. Your Notice
                            to the Otter Creek Productions, LLC, must be sent by mail to Arbitration Notice of Dispute,
                            2121 Avenue of the Stars, 7th Floor, Los Angeles, California, 90067. Otter Creek
                            Productions, LLC, will send any Notice to you at the contact information we have for you or
                            that you provide. It is the sender’s responsibility to ensure that the recipient receives
                            the Notice. During the first 45 days after you or we send a Notice to the other, you and we
                            may try to reach a settlement of the Dispute.</b></span></p>
                <p className="p1"><span className="s1"><b>If you and we do not resolve the Dispute within 45 days, either you or
                            we may initiate arbitration in accordance with the JAMS Rules. Further instructions on
                            submitting a Demand for Arbitration may be found at&nbsp;</b><a
                            href="http://www.jamsadr.com/files/Uploads/Documents/JAMS_Arbitration_Demand.pdf"><b>http://www.jamsadr.com/files/Uploads/Documents/JAMS_Arbitration_Demand.pdf</b></a><b>.
                            In addition to filing this Demand for Arbitration with JAMS in accordance with its rules and
                            procedures, you must send a copy of this completed Demand for Arbitration to Otter Creek
                            Productions, LLC, at the address listed above to which you sent your Notice of
                            Dispute.</b></span></p>
                <p className="p1"><span className="s1"><b>(5) You and Otter Creek Productions, LLC, acknowledge and agree to
                            abide by the following rules for arbitration: (a) YOU AND Otter Creek Productions, LLC, MAY
                            BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
                            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, REPRESENTATIVE OR MULTI-CLAIMANT
                            PROCEEDING, AND THE ARBITRATOR SHALL HAVE NO POWER TO AWARD CLASS-WIDE RELIEF; (b) Otter
                            Creek Productions, LLC, will pay arbitration costs as required by the JAMS Consumer
                            Arbitration Minimum Standards and consistent with paragraph 6 below; (c) the arbitrator may
                            award any individual relief or individual remedies that are permitted by applicable law; and
                            (d) each side pays his, her or its own attorneys’ fees, except as otherwise provided in
                            paragraph 8 below.</b></span></p>
                <p className="p1"><span className="s1"><b>(6) JAMS charges filing and other fees to conduct arbitrations.
                            Ordinarily, the claimant has to pay the filing fee to initiate arbitration, but if you wish
                            to commence an arbitration against Otter Creek Productions, LLC, you and Otter Creek
                            Productions, LLC, acknowledge and agree to abide by the following:</b></span></p>
                <ul className="ul1">
                    <li className="li2"><span className="s3"><b>If you are seeking to recover less than $10,000 (inclusive of
                                attorneys’ fees), LLC, will pay the filing fee on your behalf or reimburse your payment
                                of it.</b></span></li>
                    <li className="li2"><span className="s3"><b>If you are seeking to recover $10,000 or more, you will have to
                                pay the filing fee charged by JAMS, but Otter Creek Productions, LLC, will reimburse the
                                filing fee if you prevail on all claims decided upon by the arbitrator.</b></span></li>
                    <li className="li2"><span className="s3"><b>Otter Creek Productions, LLC, and you agree that, if the claims
                                to be arbitrated total less than $10,000 (inclusive of attorneys’ fees), the claim
                                ordinarily should be decided on written submissions only, without a telephonic or
                                in-person hearing. Otter Creek Productions, LLC, will not request a hearing for any
                                claims totaling less than $10,000. This provision shall not be construed by the
                                arbitrator to deprive you of any rights you may have to a telephonic or in-person
                                hearing in your hometown area pursuant to the JAMS Rules.</b></span></li>
                    <li className="li2"><span className="s3"><b>Otter Creek Productions, LLC, and you agree that, if the claims
                                to be arbitrated total $10,000 or more, the arbitration will occur in a manner and place
                                consistent with the JAMS Rules.</b></span></li>
                </ul>
                <p className="p1"><span className="s1"><b>(7) Regardless of how the arbitration proceeds, each of you and Otter
                            Creek Productions, LLC, shall cooperate in good faith in the exchange of non-privileged
                            documents and information as necessary in accordance with the JAMS Rules, and the arbitrator
                            shall issue a reasoned written decision sufficient to explain his or her findings and
                            conclusions.</b></span></p>
                <p className="p1"><span className="s1"><b>(8) Each of you and Otter Creek Productions, LLC, may incur attorneys’
                            fees during the arbitration. Each side agrees to pay his, her or its own attorneys’ fees
                            unless the claim(s) at issue permit the prevailing party to be paid its attorneys’ fees, and
                            in such instance, the fees awarded shall be determined by the applicable law(s). In addition
                            to whatever rights you may have to recover your attorneys’ fees under applicable law, if you
                            prevail in the arbitration, and if Otter Creek Productions, LLC, failed to make a settlement
                            offer to you before the arbitration or the amount you win is at least 25% greater than Otter
                            Creek Productions, LLC’s, highest settlement offer, then Otter Creek Productions, LLC, will
                            pay your reasonable attorneys’ fees in addition to the amount the arbitrator awarded. If
                            Otter Creek Productions, LLC, wins the arbitration, you will be responsible for your own
                            attorneys’ fees. In addition, if the arbitrator, at the request of the winning party, finds
                            that the losing party brought a claim or asserted a defense frivolously or for an improper
                            purpose, then regardless of the amount in dispute, the arbitrator must order the losing
                            party to pay both sides’ arbitration fees and may order the losing party to pay the winning
                            party’s reasonable attorneys’ fees, unless such an award of fees is prohibited by applicable
                            law.</b></span></p>
                <p className="p1"><span className="s1"><b>(9) The arbitrator may award declaratory or injunctive relief only in
                            favor of the individual party seeking relief and only to the extent necessary to provide
                            relief warranted by that party’s individual claim. The arbitrator may not order Otter Creek
                            Productions, LLC, to pay any monies to or take any actions with respect to persons other
                            than you, unless Otter Creek Productions, LLC, explicitly consents in advance, after an
                            arbitrator is selected, to permit the arbitrator to enter such an order. Further, unless
                            Otter Creek Productions, LLC, expressly agrees, the arbitrator may not consolidate other
                            persons’ claims with yours, and may not otherwise preside over any form of a representative,
                            multi-claimant or class proceeding.</b></span></p>
                <p className="p1"><span className="s1"><b>(10) You and Otter Creek Productions, LLC, agree to maintain the
                            confidential nature of the arbitration proceeding and shall not disclose the fact of the
                            arbitration, any documents exchanged as part of any mediation, proceedings of the
                            arbitration, the arbitrator’s decision and the existence or amount of any award, except as
                            may be necessary to prepare for or conduct the arbitration (in which case anyone becoming
                            privy to confidential information must undertake to preserve its confidentiality), or except
                            as may be necessary in connection with a court application for a provisional remedy, a
                            judicial challenge to an award or its enforcement, or unless otherwise required by law or
                            court order.</b></span></p>
                <p className="p1"><span className="s1"><b>(11) With the exception of subpart (a) in paragraph (5) (i.e., the
                            waiver of the ability to proceed on behalf of multiple claimants or a purported class), if
                            any part of this Arbitration Agreement is deemed invalid, unenforceable, or illegal, then
                            the balance of this Arbitration Agreement shall remain in effect and be construed in
                            accordance with its terms as if the invalid, unenforceable, or illegal provision were not
                            contained. If, however, subpart (a) in paragraph (5) is found invalid, unenforceable or
                            illegal, then the entirety of this Arbitration Agreement shall be null and void, but the
                            rest of this Agreement, including the provisions governing where actions against Otter Creek
                            Productions, LLC, must be pursued, the choice of governing law, and our mutual waiver of the
                            right to a trial by jury, will remain in effect and apply to any claim that, for this or any
                            other reason, proceeds in court rather than in arbitration.</b></span></p>
                <p className="p1"><span className="s1"><b>Governing Law</b></span></p>
                <p className="p1"><span className="s1"><b>The Agreement will be governed by, and construed in accordance with,
                            the laws of the State of New York, without regard to its conflict of law
                            provisions.</b></span></p>
                <p className="p1"><span className="s1"><b>Except with respect to Disputes to be resolved through an arbitration
                            process in accordance with the Arbitration Agreement contained above, you and Otter Creek
                            Productions, LLC, agree to submit to the exclusive jurisdiction of the courts located in New
                            York, New York, to resolve any Dispute arising out of the Agreement or the Company Services.
                            YOU HEREBY KNOWINGLY, VOLUNTARILY AND INTENTIONALLY WAIVE ANY RIGHT YOU MAY HAVE TO A TRIAL
                            BY JURY IN RESPECT OF ANY LITIGATION (INCLUDING, BUT NOT LIMITED TO, ANY CLAIMS,
                            COUNTERCLAIMS, CROSS-CLAIMS, OR THIRD PARTY CLAIMS) ARISING OUT OF, UNDER OR IN CONNECTION
                            WITH THIS AGREEMENT.</b></span></p>
                <p className="p1"><span className="s1"><b>YOU AGREE THAT ANY CAUSE OF ACTION YOU MAY HAVE ARISING OUT OF OR
                            RELATED TO THIS AGREEMENT,&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;OR
                            THE COMPANY SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER SUCH CAUSE OF ACTION
                            ACCRUES. AFTER SUCH PERIOD, SUCH CAUSE OF ACTION SHALL BE PERMANENTLY BARRED.</b></span></p>
                <p className="p1"><span className="s1"><b>Indemnity</b></span></p>
                <p className="p1"><span className="s1"><b>You agree to indemnify and hold Otter Creek Productions, LLC, its
                            Otter Creek Productions, LLC, Affiliates, subcontractors and other partners, and their
                            respective officers, agents, partners and employees, harmless from any loss, liability,
                            claim, or demand, including, but not limited to, reasonable attorneys’ fees, made by any
                            third party due to or arising out of or in connection with your misuse of&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>&nbsp;or
                            the Company Services (including, without, limitation, any use of your account, whether or
                            not authorized by you), your breach of this Agreement, your violation of any rights of
                            another or any Content that you Transmit through the Company Services.</b></span></p>
                <p className="p1"><span className="s1"><b>Unsolicited Submissions</b></span></p>
                <p className="p1"><span className="s1"><b>Otter Creek Productions, LLC, does not knowingly accept, via the
                            Company Services or otherwise, unsolicited submissions including, without limitation,
                            submissions by you of blog ideas, articles, scripts, story lines, fan fiction, characters,
                            drawings, information, suggestions, proposals, ideas or concepts. Otter Creek Productions,
                            LLC, requests that you do not make any unsolicited submissions. Any similarity between an
                            unsolicited submission and any elements in any Otter Creek Productions, LLC, or Affiliated
                            Otter Creek Productions, LLC, creative work including, without limitation, a film, series,
                            story, title or concept would be purely coincidental. If you do send any submissions to
                            Otter Creek Productions, LLC, via the Company Services that are unsolicited (including but
                            not limited to any Forum), however, you agree that (i) your unsolicited submissions are not
                            being made in confidence or trust and that by making such submissions no contractual or
                            fiduciary relationship is created between you and Otter Creek Productions, LLC; (ii) any
                            such unsolicited submissions and copyright become the property of and will be owned by Otter
                            Creek Productions, LLC (and are not User Content licensed by you to Otter Creek Productions,
                            LLC, under “Your Proprietary Rights in and License to Your User Content”) and may be used,
                            copied, sublicensed, adapted, transmitted, distributed, publicly performed, published,
                            displayed or deleted as Otter Creek Productions, LLC, sees fit; (iii) you are not entitled
                            to any compensation, credit or notice whatsoever in connection with such submissions; and
                            (iv) by sending an unsolicited submission you waive the right to make any claim against
                            Otter Creek Productions, LLC, or Otter Creek Productions, LLC, Affiliates relating to any
                            unsolicited submissions by you, including, without limitation, unfair competition, breach of
                            implied contract or breach of confidentiality.</b></span></p>
                <p className="p1"><span className="s1"><b>Employment Opportunities</b></span></p>
                <p className="p1"><span className="s1"><b>Otter Creek Productions, LLC, may, from time to time, post Otter Creek
                            Productions, LLC, employment opportunities on the Company Services and/or invite users to
                            submit resumes to it. If you choose to submit your name, contact information, resume and/or
                            other personal information to Otter Creek Productions, LLC, in response to employment
                            listings, you are authorizing Otter Creek Productions, LLC, to utilize this information for
                            all lawful and legitimate hiring and employment purposes. Otter Creek Productions, LLC, also
                            reserves the right, at its sole discretion, to forward the information you submit to its
                            Otter Creek Productions, LLC, Affiliates for legitimate business purposes. Nothing in this
                            Agreement or contained in the Company Services will constitute a promise by Otter Creek
                            Productions, LLC, to contact, interview, hire or employ any individual who submits
                            information to it, nor will anything in this Agreement or contained in the Company Services
                            constitute a promise that Otter Creek Productions, LLC, will review any or all of the
                            information submitted to it by users of the Company Services.</b></span></p>
                <p className="p1"><span className="s1"><b>Other</b></span></p>
                <p className="p1"><span className="s1"><b>The failure of Otter Creek Productions, LLC, to exercise or enforce
                            any right or provision of this Agreement will not operate as a waiver of such right or
                            provision. The Section titles in this Agreement are for convenience only and have no legal
                            or contractual effect. This Agreement operates to the fullest extent permissible by law.
                            Except as otherwise expressly provided herein, If any provision of this Agreement is
                            unlawful, void or unenforceable, that provision is deemed severable from this Agreement and
                            does not affect the validity and enforceability of any remaining provisions.</b></span></p>
                <p className="p1"><span className="s1"><b>You agree that any notices the Otter Creek Productions, LLC, may be
                            required by Applicable Law to send to you will be effective upon Otter Creek Productions,
                            LLC’s, sending an e-mail message to the e-mail address you have on file with Otter Creek
                            Productions, LLC, or publishing such notices on the informational page(s) of&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=http-3A__youbetyourlife.com&amp;d=DwQFaQ&amp;c=uw6TLu4hwhHdiGJOgwcWD4AjKQx6zvFcGEsbfiY9-EI&amp;r=fxQqJX-HWgrj9Etqi0PhAXooi86DvZMkG96pJbWGW0A&amp;m=eXD5H_0LPtSNfJeJHe3IUb6xvsD9SRh1MjhBdc3RYZ4&amp;s=RYt7sQ7nkEVehE5Qj9Bnv0Tsebv46q07F57beZ3yoXo&amp;e="><b>youbetyourlife.com</b></a><b>.</b></span>
                </p>
                <p className="p1"><span className="s1"><b>You agree that no joint venture, partnership, employment, or agency
                            relationship exists between you and Otter Creek Productions, LLC, as a result of this
                            Agreement or your use of the Company Services. A printed version of this Agreement and of
                            any notice related to it shall be admissible in judicial or administrative proceedings based
                            upon or relating to this Agreement to the same extent as other business documents and
                            records originally generated and maintained in printed form.</b></span></p>
                <p className="p3"><span className="s3"><b>Nothing contained in this Agreement limits Otter Creek Productions,
                            LLC’s, right to comply with governmental, court and law enforcement requests or requirements
                            relating to your use of the Company Services or information provided to or gathered by us in
                            connection with such use.</b></span><span className="s1"><b><br />
                        </b></span><span className="s3"><b>Please contact us at: Terms-FFR@fox.com with any questions
                            regarding this Agreement.</b></span></p>
                <p className="p1"><span className="s1"><b>I HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE PROVISIONS
                            CONTAINED ABOVE.</b></span></p>
            </div>
        </Card>
    </div>
</div>
);
}

export default Terms;