import React from 'react';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/CardHeader/CardHeader';

const Privacy = () => {
return (
<div className="common-container">
    <div style={{marginTop: '1px', width: '100%'}}>
        <Card>
            <div className="common-card-content">
                <CardHeader type={'common'} header={'PRIVACY'} secondHeader={'POLICY'} />
                <p className="p1"><span className="s1"><b>Table of Contents:</b></span></p>
                <ol className="ol1">
                    <li className="li2"><span className="s3"><b>SCOPE AND APPLICATION:&nbsp;What and who this Policy
                                covers</b></span></li>
                    <li className="li2"><span className="s3"><b>COLLECTION OF INFORMATION:&nbsp; The sources of and methods by
                                which we, our service providers, and our advertisers collect information from and about
                                you, including information about your interaction with the Company Services</b></span>
                    </li>
                    <li className="li2"><span className="s3"><b>USE AND DISCLOSURE:<span className="Apple-converted-space">&nbsp;
                                </span>How we use the information we collect from and about you, and who we might share
                                it with and why</b></span></li>
                    <li className="li2"><span className="s3"><b>SECURITY:<span className="Apple-converted-space">&nbsp; </span>How
                                we protect your information from loss or misuse</b></span></li>
                    <li className="li2"><span className="s3"><b>USER ACCESS AND CONTROL:<span
                                    className="Apple-converted-space">&nbsp; </span>How you can access and control the
                                information we maintain about you</b></span></li>
                    <li className="li2"><span className="s3"><b>OTHER IMPORTANT INFORMATION:<span
                                    className="Apple-converted-space">&nbsp; </span>Other things you should know about this
                                Policy and how we handle your information</b></span></li>
                    <li className="li2"><span className="s3"><b>CONTACT US:<span className="Apple-converted-space">&nbsp; </span>How
                                to contact Otter Creek Productions, LLC, about this Policy</b></span></li>
                </ol>
                <p className="p1"><span className="s1"><b>1. SCOPE AND APPLICATION</b></span></p>
                <p className="p1"><span className="s1"><b>Otter Creek Productions, LLC (“OCP” or “Company” or “we”) wants you to
                            be familiar with how we collect, use and disclose information from and about you.<span
                                className="Apple-converted-space">&nbsp; </span>This Privacy Policy describes our practices
                            in connection with information collected through services such as websites, mobile sites,
                            applications (“apps”), and widgets (collectively, the “Company Services”).<span
                                className="Apple-converted-space">&nbsp; </span>By using Company Services, you agree to the
                            terms and conditions of this Privacy Policy.<span className="Apple-converted-space">&nbsp;
                            </span>A list of our affiliated companies (“Company Affiliates”) is available at&nbsp;</b><a
                            href="http://www.foxcorporation.com/"><b>www.foxcorporation.com</b></a><b>.</b></span></p>
                <p className="p1"><span className="s1"><b>As set forth in our&nbsp;</b><a
                            href="https://www.youbetyourlife.com/story/41143088/tos"><b>Terms of Use</b></a><b>, the
                            Company Services are for a general audience, are not targeted to children, and do not
                            knowingly collect personal information from children under 13 years of age.</b></span></p>
                <p className="p1"><span className="s1"><b>This Privacy Policy applies to all users, including both those who use
                            the Company Services without being registered with a Company Service and those who have
                            registered with a Company Service.<span className="Apple-converted-space">&nbsp; </span>This
                            Privacy Policy applies to Company’s collection and use of your personal information (i.e.,
                            information that identifies a specific person, such as full name or email address).<span
                                className="Apple-converted-space">&nbsp; </span>It also describes generally Company’s
                            practices for handling non-personal information (for example, interests, demographics and
                            services usage).</b></span></p>
                <p className="p1"><span className="s1"><b>2. COLLECTION OF INFORMATION</b></span></p>
                <p className="p1"><span className="s1"><b>Company and our service providers may collect the following
                            information from and about you:</b></span></p>
                <p className="p1"><span className="s1"><b>Registration Information&nbsp;is the information you submit to
                            register for a Company Service, for example, to create an account, post comments, receive a
                            newsletter, or enter a contest or sweepstakes.<span className="Apple-converted-space">&nbsp;
                            </span>Registration Information may include, for example, name, email address, and zip
                            code.</b></span></p>
                <p className="p1"><span className="s1"><b>Public Information and Posts&nbsp;consist of comments or content that
                            you post to the Company Services and the information about you that accompanies those posts
                            or content, which may include a name, user name, comments, likes, status, profile
                            information and picture.<span className="Apple-converted-space">&nbsp; </span>Public Information
                            and Posts are always public, which means they are available to everyone and may be displayed
                            in search results on external search engines.<span
                                className="Apple-converted-space">&nbsp;</span></b></span></p>
                <p className="p1"><span className="s1"><b>Information from Social Media.<span
                                className="Apple-converted-space">&nbsp; </span>If you access or log-in to a Company Service
                            through a social media service or connect a Company Service to a social media service, the
                            information we collect may also include your user ID and/or user name associated with that
                            social media service, any information or content you have permitted the social media service
                            to share with us, such as your profile picture, email address or friends lists, and any
                            information you have made public in connection with that social media service.<span
                                className="Apple-converted-space">&nbsp; </span>When you access the Company Services through
                            social media services or when you connect a Company Service to social media services, you
                            are authorizing Company to collect, store, and use such information and content in
                            accordance with this Privacy Policy.</b></span></p>
                <p className="p1"><span className="s1"><b>Activity Information.<span className="Apple-converted-space">&nbsp;
                            </span>When you access and interact with the Company Services, Company and its service
                            providers may collect certain information about those visits.<span
                                className="Apple-converted-space">&nbsp; </span>For example, in order to permit your
                            connection to the Company Services, our servers receive and record information about your
                            computer, device, and browser, including potentially your IP address, browser type, and
                            other software or hardware information.<span className="Apple-converted-space">&nbsp; </span>If
                            you access the Company Services from a mobile or other device, we may collect a unique
                            device identifier assigned to that device, geolocation data (including your precise
                            location), or other transactional information for that device.</b></span></p>
                <p className="p1"><span className="s1"><b>Cookies and other tracking technologies (such as browser cookies,
                            pixels, beacons, and Adobe Flash technology including cookies) are comprised of small bits
                            of data.<span className="Apple-converted-space">&nbsp; </span>Company’s websites, apps and other
                            services send this data to your browser when you first request a web page and then store the
                            data on your computer or other device so the website or app can access information when you
                            make subsequent requests for pages from that service.<span
                                className="Apple-converted-space">&nbsp; </span>These technologies may also be used to
                            collect and store information about your usage of the Company Services, such as pages you
                            have visited, and the video and other content you have viewed.</b></span></p>
                <p className="p1"><span className="s1"><b>Other parties that support the Company Services by serving
                            advertisements or providing services, such as allowing you to share content or tracking
                            aggregate Company Services usage statistics, may also use these technologies to collect
                            similar information when you use the Company Services or other parties’ services.<span
                                className="Apple-converted-space">&nbsp; </span>Those parties may also use these
                            technologies, along with activity information they collect, to recognize you across the
                            devices you use, such as a mobile device and a laptop or other computer.<span
                                className="Apple-converted-space">&nbsp; </span>Company does not control these technologies
                            and their use is governed by the privacy policies of parties using such technologies.<span
                                className="Apple-converted-space">&nbsp; </span>For more information about third-party
                            advertising networks and similar entities that use these technologies, including your
                            choices with respect to them, see the section entitled “To deliver relevant advertisements,”
                            below.</b></span></p>
                <p className="p1"><span className="s1"><b>Your browser may be initially set to accept cookies, but you can
                            change your settings to notify you when a cookie is being set or updated, or to block
                            cookies altogether.<span className="Apple-converted-space">&nbsp; </span>Please consult the
                            “Help” section of your browser for more information.<span
                                className="Apple-converted-space">&nbsp; </span>Users can manage the use of Flash
                            technologies, with the Flash management tools available at Adobe’s website, see&nbsp;</b><a
                            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html"><b>http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html</b></a><b>.<span
                                className="Apple-converted-space">&nbsp; </span>Please note that by blocking any or all
                            cookies you may not have access to certain features, content or personalization available
                            through the Company Services.</b></span></p>
                <p className="p1"><span className="s1"><b>Information from Other Sources.<span
                                className="Apple-converted-space">&nbsp; </span>We may supplement the information we collect
                            with information from other sources, such as publicly available information about your
                            online and offline activity from social media services, commercially available sources, and
                            information from our Company Affiliates or business partners.</b></span></p>
                <p className="p1"><span className="s1"><b>Do Not Track Signals.<span className="Apple-converted-space">&nbsp;
                            </span>At this time we do not respond to browser ‘Do Not Track’ signals, as we await the
                            work of interested stakeholders and others to develop standards for how such signals should
                            be interpreted.</b></span></p>
                <p className="p1"><span className="s1"><b>3. USE AND DISCLOSURE</b></span></p>
                <p className="p1"><span className="s1"><b>We may use the information we collect from and about you to provide
                            the Company Services and features to you, including: to measure and improve those Company
                            Services and features and to develop new products and services; to improve your experience
                            with both online and off-line Company Services by delivering content you will find relevant
                            and interesting, including advertising and marketing messages; to allow you to comment on
                            content, and participate in online games, contests, other promotions, or rewards programs;
                            to provide you with customer support and to respond to inquiries.<span
                                className="Apple-converted-space">&nbsp; </span>In order to provide you with content and
                            advertising that is more interesting and relevant to you, we may use the information from
                            and about you to make inferences and predictions about your potential areas of
                            interest.<span className="Apple-converted-space">&nbsp; </span>When the information collected
                            from or about you does not identify you as a specific person, we may use that information
                            for any purpose or share it with other parties, to the extent permitted by applicable
                            law.</b></span></p>
                <p className="p1"><span className="s1"><b>We use the information we collect from and about you for these
                            additional purposes:</b></span></p>
                <p className="p1"><span className="s1"><b>To allow service providers to assist us in providing and managing the
                            Company Services.<span className="Apple-converted-space">&nbsp; </span>The information we
                            collect from and about you may be made available to certain service providers, such as
                            contractors, analytics and other measurement companies, agents or sponsors, who help us
                            analyze and understand your use of the Company Services, and manage and/or provide the
                            Company Services.</b></span></p>
                <p className="p1"><span className="s1"><b>To allow social sharing functionality.&nbsp;If you log in with or
                            connect a social media service account with Company Services, we may share your user name,
                            picture, and likes, as well as your activities and comments with other Company Services
                            users and with your friends associated with your social media service.<span
                                className="Apple-converted-space">&nbsp; </span>We may also share the same information with
                            the social media service provider.<span className="Apple-converted-space">&nbsp; </span>By
                            logging in with or connecting your Company Services account with a social media service, you
                            are authorizing us to share information we collect from and about you with the social media
                            service provider, other users and your friends and you understand that the social media
                            service’s use of the shared information will be governed by the social media service’s
                            privacy policy.<span className="Apple-converted-space">&nbsp; </span>If you do not want your
                            personal information shared in this way, please do not connect your social media service
                            account with your Company Services account and do not participate in social sharing on
                            Company Services.</b></span></p>
                <p className="p1"><span className="s1"><b>To provide co-branded services and features.<span
                                className="Apple-converted-space">&nbsp; </span>We may offer co-branded services or
                            features, such as contests, sweepstakes or other promotions together with other companies
                            (“Co-Branded Services”).<span className="Apple-converted-space">&nbsp; </span>These Co-Branded
                            Services may be hosted on Company Services or on that other party’s service.<span
                                className="Apple-converted-space">&nbsp; </span>By virtue of these relationships, we may
                            share the information you submit in connection with the Co-Branded Service with that
                            party.<span className="Apple-converted-space">&nbsp; </span>That other party’s use of your
                            information will be governed by its privacy policy.</b></span></p>
                <p className="p1"><span className="s1"><b>To deliver relevant advertisements.&nbsp;Company and our service
                            providers may use the information we collect from and about you as described in this Privacy
                            Policy to deliver relevant ads to you when you use the Company Services or another service
                            (on any of your devices).<span className="Apple-converted-space">&nbsp; </span>As noted above
                            advertising networks and advertisers may use cookies and similar technologies to collect and
                            track information such as demographic information, inferred interests, aggregated
                            information, and information about the different devices you use and your activity on such
                            devices, to assist them in delivering advertising that is more relevant to your
                            interests.<span className="Apple-converted-space">&nbsp; </span>To find out more about
                            advertising networks and similar entities that use these technologies, see&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=https-3A__youradchoices.com_&amp;d=DwMFAg&amp;c=Y6lK3sQOQe5gkvL3EqgybQ&amp;r=3zkCLdirI1CuYsPBetG2xxOA8mEd8_duzcF2SVtkLtQ&amp;m=mR185RgfHfCdmGH1Jrmdya_E0bxIHysaoQZZF8pQMHU&amp;s=JGVFuTRNRqOj8JaWazxxrmWiwtVP6mF2_UO-e4fREmg&amp;e="><b>https://youradchoices.com/</b></a><b>.<span
                                className="Apple-converted-space">&nbsp; </span>If you wish to opt out of tailored
                            advertising in desktop and mobile browsers, please visit&nbsp;</b><a
                            href="https://protect-us.mimecast.com/s/hmenClYMX0hEWQXpSG8oQ7"><b>http://optout.aboutads.info/#/</b></a><b>&nbsp;or&nbsp;</b><a
                            href="https://protect-us.mimecast.com/s/BSWeCmZM8kilVOWDTObefD"><b>http://optout.networkadvertising.org/#</b></a><b>&nbsp;on
                            each of your devices and browsers.&nbsp; To opt out in mobile apps, you may use your
                            device’s “Limit Ad Tracking” or similar settings or download and use the AppChoices app
                            at&nbsp;</b><a
                            href="https://urldefense.proofpoint.com/v2/url?u=https-3A__youradchoices.com_appchoices&amp;d=DwMFAg&amp;c=Y6lK3sQOQe5gkvL3EqgybQ&amp;r=3zkCLdirI1CuYsPBetG2xxOA8mEd8_duzcF2SVtkLtQ&amp;m=mR185RgfHfCdmGH1Jrmdya_E0bxIHysaoQZZF8pQMHU&amp;s=XbtOJCGXlbcFwuMZgX_vFLIA58yIWj5F8h0cow_wtyY&amp;e="><b>https://youradchoices.com/appchoices</b></a><b>.&nbsp;
                            To opt out on connected TVs, please use the&nbsp;“Limit Ad Tracking,“ “Interest-based Ads,”
                            or equivalent settings on your device.&nbsp; Please note that opting-out does not mean that
                            you will stop seeing all ads.</b></span></p>
                <p className="p1"><span className="s1"><b>To contact you.&nbsp;Company may periodically send promotional
                            materials or notifications related to the Company Services.<span
                                className="Apple-converted-space">&nbsp; </span>If you want to stop receiving promotional
                            materials, you can change your account setting or follow the unsubscribe instructions at the
                            bottom of each email.&nbsp; There are certain service notification and other non-promotional
                            emails that you may not opt-out of, such as notifications of changes to the Company Services
                            or policies.<span className="Apple-converted-space">&nbsp; </span>If you have installed a mobile
                            app and you wish to stop receiving push notifications, you can change the settings either on
                            your mobile device or through the app.</b></span></p>
                <p className="p1"><span className="s1"><b>To share with our Company Affiliates.<span
                                className="Apple-converted-space">&nbsp; </span>Company may share your information with
                            Company Affiliates for the purposes described in this Privacy Policy.<span
                                className="Apple-converted-space">&nbsp; </span>A link to a website that lists Company
                            Affiliates can be found at the beginning of this policy.<span
                                className="Apple-converted-space">&nbsp; </span>Users who visit Company Affiliates’ services
                            should still refer to their separate privacy policies, which may differ in some respects
                            from this Privacy Policy.</b></span></p>
                <p className="p1"><span className="s1"><b>To share with business partners.&nbsp;Company may share your
                            information with business partners to permit them to send you marketing communications
                            consistent with your choices.</b></span></p>
                <p className="p1"><span className="s1"><b>To protect the rights of Company and others.<span
                                className="Apple-converted-space">&nbsp; </span>There may be instances when Company may use
                            or disclose your information, including situations where Company has a good faith belief
                            that such use or disclosure is necessary in order to: (i) protect, enforce, or defend the
                            legal rights, privacy, safety, or property of Company, our Company Affiliates or their
                            employees, agents and contractors (including enforcement of our agreements and our terms of
                            use); (ii) protect the safety, privacy, and security of users of the Company Services or
                            members of the public; (iii) protect against fraud or for risk management purposes; (iv)
                            comply with the law or legal process; or (v) respond to requests from public and government
                            authorities.</b></span></p>
                <p className="p1"><span className="s1"><b>To complete a merger or sale of assets.<span
                                className="Apple-converted-space">&nbsp; </span>If Company sells all or part of its business
                            or makes a sale or transfer of its assets or is otherwise involved in a merger or transfer
                            of all or a material part of its business (including in connection with a bankruptcy or
                            similar proceedings), Company may transfer your information to the party or parties involved
                            in the transaction as part of that transaction.</b></span></p>
                <p className="p1"><span className="s1"><b>4. SECURITY</b></span></p>
                <p className="p1"><span className="s1"><b>Company uses commercially reasonable administrative, technical,
                            personnel, and physical measures designed to safeguard information in its possession against
                            loss, theft and unauthorized use, disclosure, or modification.<span
                                className="Apple-converted-space">&nbsp; </span>However, no one can guarantee the complete
                            safety of your information.</b></span></p>
                <p className="p1"><span className="s1"><b>5. USER ACCESS AND CONTROL</b></span></p>
                <p className="p1"><span className="s1"><b>If you would like to access, review, correct, update, suppress,
                            delete, or otherwise limit our use of your personal information you have previously provided
                            directly to us, you may contact us as outlined in Section 7 and 8; or using the mechanisms
                            provided below.<span className="Apple-converted-space">&nbsp; </span>In your request, please
                            include your email address, name, address, and telephone number and specify clearly what
                            information you would like to access, change, update, suppress, or delete.<span
                                className="Apple-converted-space">&nbsp; </span>We will try to comply with your request as
                            soon as reasonably practicable and consistent with applicable law.</b></span></p>
                <p className="p1"><span className="s1"><b>If you are a resident of California, you may request that we not share
                            your personal information on a going-forward basis with Company Affiliates or unaffiliated
                            third parties for their direct marketing purposes by contacting us as outlined in Section 7
                            and putting “shine the light opt-out” in the subject line and your full name, email address,
                            and postal address in the body of the email.<span className="Apple-converted-space">&nbsp;
                            </span>We will try to comply with your request(s) as soon as possible.</b></span></p>
                <p className="p1"><span className="s1"><b>6. OTHER IMPORTANT INFORMATION</b></span></p>
                <p className="p1"><span className="s1"><b>Updates to Privacy Policy.<span className="Apple-converted-space">&nbsp;
                            </span>Company may modify this Privacy Policy.<span className="Apple-converted-space">&nbsp;
                            </span>Please look at the Effective Date at the top of this Privacy Policy to see when this
                            Privacy Policy was last revised.<span className="Apple-converted-space">&nbsp; </span>Any
                            changes to this Privacy Policy will become effective when we post the revised Privacy Policy
                            on the Company Services.</b></span></p>
                <p className="p1"><span className="s1"><b>Location of Data.<span className="Apple-converted-space">&nbsp; </span>The
                            Company Services are hosted in and managed from the United States.<span
                                className="Apple-converted-space">&nbsp; </span>If you are a user located outside the United
                            States, you understand and consent to having any personal information processed in the
                            United States or in any other country in which we have facilities or in which we engage
                            service providers.<span className="Apple-converted-space">&nbsp; </span>United States and other
                            countries’ data protection laws may not be the same as those in your
                            jurisdiction.</b></span></p>
                <p className="p1"><span className="s1"><b>Linked Services.<span className="Apple-converted-space">&nbsp; </span>The
                            Company Services may be linked to sites operated by unaffiliated companies, and may carry
                            advertisements or offer content, functionality, games, newsletters, contests or sweepstakes,
                            or applications developed and maintained by unaffiliated companies.<span
                                className="Apple-converted-space">&nbsp; </span>Company is not responsible for the privacy
                            practices of unaffiliated companies,&nbsp;and once you leave the Company Services or click
                            an advertisement you should check the applicable privacy policy of the other
                            service.&nbsp;<span className="Apple-converted-space">&nbsp; &nbsp;</span></b></span></p>
                <p className="p1"><span className="s1"><b>In addition, Company is not responsible for the privacy or data
                            security practices of other organizations, such as Facebook, Twitter, Apple, Google,
                            Microsoft or any other app developer, app provider, social media platform provider,
                            operating system provider, wireless service provider, or device manufacturer, including in
                            connection with any information you disclose to other organizations through or in connection
                            with the Company Services.</b></span></p>
                <p className="p1"><span className="s1"><b>Data Retention.<span className="Apple-converted-space">&nbsp; </span>We
                            will retain your information for the period necessary to fulfill the purposes outlined in
                            this Privacy Policy unless a longer retention period is required or allowed by
                            law.</b></span></p>
                <p className="p1"><span className="s1"><b>Remember that even after you cancel your account, or if you ask us to
                            delete your personal information, copies of some information from your account may remain
                            viewable in some circumstances where, for example, you have shared information with social
                            media or other services.<span className="Apple-converted-space">&nbsp; </span>Because of the
                            nature of caching technology, your account may not be instantly inaccessible to others.<span
                                className="Apple-converted-space">&nbsp; </span>We may also retain backup information
                            related to your account on our servers for some time after cancellation or your request for
                            deletion, for fraud detection or to comply with applicable law or our internal security or
                            recordkeeping policies.<span className="Apple-converted-space">&nbsp; </span>It may not always
                            be possible to completely remove or delete all of your information due to technical
                            constraints or contractual, financial or legal requirements.</b></span></p>
                <p className="p1"><span className="s1"><b>7.&nbsp;CONTACT US</b></span></p>
                <p className="p1"><span className="s1"><b>If you have questions about this Privacy Policy, please contact us
                            at:</b></span></p>
                <p className="p1"><span className="s1"><b>Email:&nbsp;</b><a
                            href="mailto:privacy-scpi@foxtv.com"><b>privacy-ffr@fox.com</b></a></span></p>
                <p className="p1"><span className="s1"><b>By mail: You Bet Your Life, 1999 S. Bundy Drive, 3</b></span><span
                        className="s4"><b>rd</b></span><span className="s1"><b>&nbsp;Floor, Los Angeles, CA 90025, Attn: Stephen
                            Brown/Production</b></span></p>
            </div>
        </Card>
    </div>
</div>
);
}

export default Privacy;