import React, { Fragment } from 'react';
import Title from '../Title/Title';

const CardHeader = props => {
    const { type, header, secondHeader } = props;
    return (
        <Fragment>
            <Title
                fontSize={'28px'}
                fontWeight={'800'}
                color={'#06426A'}>
                    {header}
            </Title>
            { type === 'common' && <Title fontSize={'28px'} fontWeight={'800'} color={'#4EA6FF'}>{secondHeader}</Title> }
            <div
                style={{
                    margin: type === 'common' ? '20px 0' : '10px 0',
                    borderBottom: '1px solid #CCCCCC'}}>
            </div>
        </Fragment>
    );
}

export default CardHeader;
