import React from 'react';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/CardHeader/CardHeader';
import classes from './SweepstakesRules.module.scss';

const SweepstakesRules = () => {
return (
<div className="common-container">
    <div className={classes.SweepstakesPage}>
        <Card>
            <div className="common-card-content">
                <CardHeader type={'common'} header={'SWEEPSTAKES'} secondHeader={'RULES'} />
                <p className="p1" style={{textAlign: 'center'}}><b>You Bet Your Life QR Code Sweepstakes</b></p>
                <p className="p1" style={{textAlign: 'center'}}>
                    <b>OFFICIAL RULES</b> <br/>
                    NO PURCHASE NECESSARY.<br/>
                    VOID <span className="s1">WHERE PROHIBITED BY LAW.</span>
                </p>
                <ol className="ul1">
                    <li className="li6"><strong>Eligibility: </strong>The You Bet Your Life QR Code Sweepstakes
                        (“Sweepstakes”) is only open to<span className="s1"> permanent legal residents of the fifty (50)
                            states of the U.S. and the District of Columbia (“D.C.”) (excluding Guam, Puerto Rico, and
                            all other U.S. territories and possessions) who are currently physically located therein.
                            All entrants must be eighteen (18) years of age or older as of the date of entry. Entrants
                            must have either a valid Social Security number or a valid U.S. Individual Taxpayer
                            Identification Number. </span>Employees of Otter Creek Productions <span
                            className="s1">(“Sponsor”), </span>Fox First Run, LLC, the <span className="s1"><i>You Bet
                                Your
                                Life</i><b><i> </i></b>television program (“Show”), </span>Enteractive Solutions Group,
                        Inc. (“Sweepstakes<span className="s1"> Administrator”), Sullivan Compliance Company,
                            participating
                            stations, stations airing the Show, streaming services, cable operators</span>, <span
                            className="s1">multiple service operators</span>, their advertising or promotion agencies,
                        those
                        involved in the production, development, distribution, implementation or handling of the Show or
                        the Sweepstakes, any agents acting for the above entities, their respective parent companies,
                        officers, directors, subsidiaries, affiliates, service providers, prize suppliers, or any other
                        person or entity associated with the Sweepstakes (collectively "Sweepstakes Entities") are
                        ineligible to enter the Sweepstakes. Immediate family members (i.e. a spouse, domestic partner,
                        child, sibling, or parent) and/or any person, whether related or not, living in the same
                        household of an individual affiliated with a Sweepstakes Entity are also ineligible to enter the
                        Sweepstakes.<span className="Apple-converted-space">&nbsp;</span></li>
                    <li className="li6"><strong>Sweepstakes Period:</strong>&nbsp;For all purposes of this Sweepstakes,
                        the
                        Sponsor’s computer is the official clock, and all times refer to Pacific Time (“PT”). <span>This
                            Sweepstakes consists of one hundred eighty (180) individual non-connecting sweepstakes (each
                            an “Individual Sweepstakes”) corresponding with each first-run</span><span className="s1">
                            episode of the Show. Each Individual Sweepstakes begins at 12:00:01 a.m. PT and ends at
                            11:59:59 p.m. PT each day wherein a first-run episode(s) airs, beginning Monday, September
                            13, 2021 and ending Friday, May 20, 2022 (“Sweepstakes Period”). Entrants should check local
                            TV listings for Show schedules in their local viewing area, which may be changed or
                            pre-empted without notice</span><span>
                        </span><span className="s1">in
                            one or more broadcast areas (e.g. due to changes to the production schedule, special
                            announcements, programs that may disrupt normal TV programming or local station programming
                            pre-emptions). In these cases, at the election of the Show, the Sweepstakes may be played
                            outside the above-stated dates, individual Shows and their corresponding call-to-action
                        </span><span>for
                            the Sweepstakes </span><span className="s1">may
                            be aired on dates different than originally scheduled, or, the Sponsor may not re-broadcast
                            that particular Show.</span></li>
                    <li className="li6"><b>How to Enter:</b> During each Show, the host will bring up an eligible
                        contestant
                        from the studio audience and ask a question to which he/she will provide an answer. Prospective
                        entrants will be prompted by the host to also participate by scanning <span>the
                            QR Code displayed on the screen which will direct them to a microsite (“Microsite”) where
                            they must provide the following information: first name, last name, </span><span
                            className="s1">telephone
                            number, email address, the answer (“Answer”) to a multiple choice question (“Question”), and
                            agree to abide by these Official Rules and Sponsor’s Terms of Use (“Entry Information”).
                        </span><span>After
                            successfully </span><span className="s2">submitting
                            the Entry Information, said entrant will then receive one (1) entry into the applicable
                            Individual Sweepstakes (“Entry”). </span><span className="s1">Answer(s)
                            must be correct </span><span>and
                        </span><span className="s1">entered
                            within the corresponding Individual Sweepstakes in order to be eligible. </span><b>Entries
                            for the last </b><span className="s1"><b>Individual
                                Sweepstakes</b> </span><b>must
                            be received by </b><span className="s1"><b>11:59:59
                                p.m. </b></span><b>PT
                            on May 20, 2022</b><span className="s1"><b>.</b></span><b>
                        </b><span className="s1"><b>Limit
                                one (1) Entry per person/per email address/per Individual Sweepstakes.
                            </b></span><span>All
                            required Entry Information must be completed in full, </span><span className="s1">be
                            current, accurate, and valid</span><span>.
                            The&nbsp;Entry Information must match the information of the entrant that completed the act
                            of physically entering the Sweepstakes</span><span className="s1">.
                            If an </span><span>entrant</span><span className="s1">
                            is from a jurisdiction that deems him/her to be a minor, he/she must obtain parental/legal
                            guardian consent prior to entering or submitting any personally identifiable information.
                            For the purposes of this Sweepstakes, a minor is defined as </span><span>a
                            resident of Alabama or Nebraska who is under nineteen (19) years of age, or a resident of
                            Mississippi who is under twenty-one (21) years of age (“Minor”)</span><span className="s1">.
                            By submitting an entry form, each </span><span>entrant</span><span className="s1">
                            confirms the accuracy and veracity of the statements and information contained therein.
                        </span><span>Proof
                            of submitting an Entry will not be deemed by the Sponsor as proof of entry into the
                            Sweepstakes. Any attempt by an entrant to obtain more than the stated number of Entries by
                            using multiple/different email addresses, identities, registrations, logins, or any other
                            methods other than as described herein, including, but not limited to, commercial
                            contest/sweepstakes subscription notification and/or entering services, will void that
                            entrant’s Entries and that Entrant may be disqualified. If entrants use a web-enabled mobile
                            device to participate, data rates may apply (entrants should contact their service provider
                            for data rates/plans). Entrants should use care and not drive or operate machinery while
                            entering the Sweepstakes.</span><b><span className="Apple-converted-space">&nbsp;
                                &nbsp;</span></b></li>
                    <li className="li6"><b>Fan Fun:</b> After each Individual Sweepstakes ends, Shows that are aired
                        again
                        (via repeats or streaming services) that contain the call-to-action QR code will re-direct
                        viewers to a microsite (“Fan Microsite”) that is separate from the Sweepstakes Microsite. Once a
                        viewer is at the Fan Microsite, he/she must follow the registration steps and correctly answer a
                        question to earn points. Each correct answer earns ten (10) points. Incorrect answers will not
                        result in any points for any participant. As fans continue to participate, they will be served
                        different Questions and accumulate points over time for the opportunity to earn Show-related
                        items including, but not limited to, hats and t-shirts. Points have no monetary value and a
                        participant must abide by the terms and conditions of the Fan Microsite. Participation in the
                        Fan Microsite will not result in a prize being awarded to a participant and is <span
                            className="s3">not
                        </span><span className="s1">required
                            for entry into the Sweepstakes.<span className="Apple-converted-space">&nbsp;</span></span>
                    </li>
                    <li className="li6"><b>P</b><span className="s1"><b>rizes/Odds
                                of Winning: </b></span><span>A
                            total of one hundred eighty (180) separate prizes will be awarded in the Sweepstakes (one
                            (1) prize per Individual Sweepstakes). Each winner will receive the same prize that was
                            available to the audience contestant on the Show. Prizes may include a gift card or a paid
                            subscription service for a pre-determined amount of time valued between $200 and $500
                            (actual prize awarded in Sponsor’s sole discretion and subject to availability).</span><b>
                            The approximate retail value (“ARV”) of each prize:&nbsp;up to $500.</b><span>
                        </span><b>Odds
                            of winning depend upon the total number of eligible Entries received for that particular
                            Individual Sweepstakes.</b><span>
                        </span><b>Limit
                            one (1) prize per person for the entire Sweepstakes. </b><span>No
                            cash equivalent for a prize(s) will be available. Gift card/subscription service cannot be
                            exchanged/redeemed for cash or combined with any other offer and is subject to stated terms
                            and conditions. The</span><b>
                        </b><span className="s1">prizes
                        </span><span>are
                            not transferable. However, Sponsor may, in its sole discretion choose to substitute a prize
                            (or prize component) of equal or greater value due to unavailability or for any other
                            reason.</span><b>
                        </b><span>All
                            expenses </span><span className="s1">associated
                            with the receipt or use of a prize </span><span>not
                            specifically mentioned as being included are excluded and is the sole responsibility of each
                            winner(s).</span><b>
                        </b><span>As
                            applicable,</span><b>
                        </b><span>prizes
                            will only be mailed/shipped </span><span className="s1">to
                            street addresses in the U.S. and D.C. </span><span>All
                            federal, state, or other tax liabilities (including but not limited to income taxes) arising
                            from the Sweepstakes or award of a prize will be the sole responsibility of each winner. If
                            prize value is $600 or more, winner(s)</span><span className="s1">
                            will receive an IRS Form </span><span>1099-MISC
                            from the Sponsor or Sponsor’s designee for the total ARV of the prize won (as stated herein)
                            in the calendar year won.</span><span className="s1">
                        </span><span>Any
                            depiction of the prize(s) in promotional materials or otherwise is for illustrative purposes
                            only. </span><span className="s1">Final
                            eligibility for the award of any prize is subject to eligibility verification as set forth
                            in these Official Rules.</span><span>
                            The Sweepstakes Administrator is not the supplier or guarantor of any prize.</span></li>
                    <li className="li6"><b>Drawings:</b><span>
                            One (1) potential winner for each Individual Sweepstakes will be randomly selected from all
                            eligible Entries received during the corresponding Individual Sweepstakes in a series of
                            drawings to be held on or about two (2) business days after each Individual Sweepstakes ends
                            by the Sweepstakes Administrator (“Random Drawing(s)”). Non-winning eligible Entries will
                            not be retained or entered into subsequent Individual Sweepstakes.</span><span
                            className="Apple-converted-space">&nbsp;</span>
                    </li>
                    <li className="li6"><b>Notification/Verification: </b>Each potential winner will be notified at the
                        telephone number provided on his/her Entry by the Sponsor/Sweepstakes Administrator within
                        approximately three (3) business days from the applicable Random Drawing. If a potential
                        winner(s) is from a state that deems him/her to be a Minor, his/her parent/legal guardian will
                        be notified instead. <span>If
                            potential winner(s) cannot be reached after a reasonable effort has been exerted; if he/she
                            is found to be ineligible; if he/she does not comply with these Official Rules; or if
                            his/her prize or prize notification is returned as undeliverable, such potential winner(s)
                            will be disqualified and an alternate potential winner(s) may be selected in Sponsor’s sole
                            discretion.</span></li>
                    <li className="li6"><b>Privacy
                            Policy: </b><span>Any
                            personal information supplied by entrants to the Sponsor or its affiliates will be subject
                            to the Show’s Privacy Policy (https://youbetyourlife.com/privacy-policy) and Terms of
                            Service (https://youbetyourlife.com/terms</span><span className="s5">).</span><span>
                            Thus, except where prohibited by law and Show’s Privacy Policy: (1) entry and/or a winner’s
                            acceptance of a prize constitutes permission for the Sweepstakes Entities to use the such
                            person’s name, address (city and state), photo(s), likeness, biographical information,
                            statement and voice for advertising/publicity purposes worldwide and in all forms of media
                            (including posting on the Show’s Microsite, website, and social media channels) in
                            perpetuity, without further notice to or compensation in connection with the Sweepstakes or
                            the prize awarded; and (2) the Sponsor has the right to modify, edit, adapt, electronically
                            alter, use, assign and/or dispose of such Entries however it sees fit and for any purpose
                            without any notice or compensation to an Entrant or any third parties. Entrants understand,
                            agree, and consent that any personally identifiable information provided to the Sweepstakes
                            Administrator may be retained and used for the purposes of this Sweepstakes (including but
                            not limited to: record keeping, tax forms, state registrations, and/or any Prize fulfillment
                            related obligations). The Sweepstakes Administrator does not and will not sell the
                            personally identifiable information of any entrant or winner. Furthermore, the Sweepstakes
                            Administrator will not share or disclose any personally identifiable information to any
                            third-party company or business unrelated to the Sweepstakes. </span><span
                            className="s4">Additionally,
                            if an </span><span>E</span><span className="s4">ntrant
                            chooses to opt-in to receive communications from a participating
                        </span><span>Sweepstakes</span><span className="s4">
                            Entity, </span><span>his/her
                            Entry Information will be subject to that Sweepstakes Entity’s privacy policy and terms of
                            service (if applicable), and such entrant agrees to receive additional email communication
                            from that particular Sweepstakes Entity. Opting in to receive communications does not
                            improve one’s chance of winning. </span><span className="s4">The
                            privacy policy for any </span><span>Sweepstakes
                        </span><span className="s4">Entity
                            participating in an opt-in will be accessible from the </span><span>Sweepstakes</span><span
                            className="s4">
                            entry form(s).</span></li>
                    <li className="li6"><b>General
                            Conditions:</b><span>
                            The Sweepstakes is governed by all applicable federal, state, and local laws, and by these
                            Official Rules. By entering the Sweepstakes, each entrant agrees and acknowledges to be
                            bound by these Official Rules, as well as the decisions of the Sponsor/Sweepstakes
                            Administrator which are final and binding in all respects; to waive any rights to claim
                            ambiguity with these Official Rules; to indemnify and hold harmless the Sweepstakes Entities
                            from any and all liability resulting or arising from the Sweepstakes or the use of any
                            submitted Entry; and to release all rights to bring any claim, action, or proceeding against
                            the Sweepstakes Entities. Once submitted, Entries become the sole property of Sponsor and
                            will not be returned. Sponsor reserves the right, in its sole discretion, to disqualify any
                            individual found to be tampering with the entry process or the operation of the Sweepstakes;
                            to be acting in violation of the Official Rules; or to be acting in an unsportsmanlike or
                            disruptive manner, or with intent to annoy, abuse, threaten, or harass any other person. Any
                            person attempting to defraud or in any way tamper with this Sweepstakes will be ineligible
                            for a prize. If, for any reason, the Sweepstakes is not capable of running as originally
                            planned, the Sponsor in its sole discretion, reserves the right to cancel, suspend, or
                            modify the Sweepstakes or any portion(s) thereof and instruct the Sponsor’s designee(s) to
                            conduct the applicable Random Drawing(s) and award the prize(s) from all salvageable
                            eligible Entries received prior to and/or after (as appropriate) the action taken by Sponsor
                            or award the prize(s) in a manner that is fair and equitable. </span><span
                            className="s1">Notice
                            of such action by the Sponsor will be posted at the Microsite</span><span className="s6">
                        </span><span className="s1">as
                            well as any other applicable social media platforms</span><span>.
                            In case of a dispute over the identity of an Entrant who submitted a winning Entry, the
                            authorized account holder of the email address associated with that Entry will be deemed the
                            Entrant. Said person must be the Entrant, comply with these Official Rules,</span><span
                            className="s7">
                        </span><span>be
                            the person who completed the act of entering, and, in Sponsor's sole discretion, may be
                            required to provide proof of ownership of the email account</span><span
                            className="s1">.</span>
                    </li>
                    <li className="li6"><b>Disclaimers:</b><span>
                            The </span><span className="s1">Sweepstakes</span><span>
                            Entities are not responsible or liable for Entries that are entered by other than human
                            means (such as by an automated computer program or any non-human mechanism, entity, or
                            device), in excess of the stated limit, or for Entries that are illegible, late, tampered
                            with, forged, incomplete, misdirected, deleted, damaged, lost, misplaced, stolen, destroyed,
                            dropped,</span><span className="s1">
                            inaccessible, corrupted, jumbled,</span><span>
                            or otherwise not in compliance with these Official Rules and such Entries will be
                            disqualified. By entering the </span><span className="s1">Sweepstakes</span><span>,
                            each entrant agrees and acknowledges that </span><span
                            className="s1">Sweepstakes</span><span>
                            Entities shall have no responsibility or liability (including, but not limited to, liability
                            for any property loss, damage, personal injury, or death) in connection with: participation
                            in this </span><span className="s1">Sweepstakes</span><span>;
                            human error; incorrect or inaccurate transcription of Entry Information; </span><span
                            className="s1">pre-emption/interruption/cancellation
                            of the Show, promos, or elements of Sweepstakes and/or segment(s) featuring the Question;
                            including but not limited to disputes regarding Answers or ambiguous Questions</span><span>;
                            acceptance/possession, use/misuse, and or defects of a prize awarded herein; </span><span
                            className="s1">any
                            technical or mechanical error when posting Questions on the Microsite;
                        </span><span>typographical
                            errors in the promotional material; any technical malfunctions of the telephone network,
                            computer online system, computer dating mechanism, computer equipment, software, or internet
                            service provider(s); interruption or inability to access </span><span className="s1">(or
                            Sponsor’s/Sweepstakes</span><span>
                        </span><span className="s1">Administrator’s
                            inability to access) </span><span>the
                            Sweepstakes, the Microsite or online service via the internet due to hardware or software
                            compatibility problems; </span><span className="s1">wireless
                            network dead zones or obstructions; </span><span>any
                            damage to an entrant’s (or any third person’s) computer/mobile device and/or its contents
                            related to or resulting from any part of this </span><span
                            className="s1">Sweepstakes</span><span>
                        </span><span className="s1">or
                            from entering or downloading/uploading materials/software in connection with the
                            Sweepstakes</span><span>;
                            any lost/delayed data transmissions, omissions, interruptions, defects or any other error’s
                            or malfunctions, </span><span className="s8">fraudulent
                            (including unauthorized) gift card uses made with lost, stolen, misplaced, or
                            misappropriated gift cards, incomplete (including inaccurate) gift card database information
                            and cancelled gift card uses or fraudulent/misappropriated uses of a subscription service
                            due to incomplete (including inaccurate) database information or for any reason</span><span
                            className="s1">
                        </span><span>even
                            if caused by the negligence of one of the </span><span
                            className="s1">Sweepstakes</span><span>
                            Entities. Entrants hereby acknowledge that said Sweepstakes Entities have neither made nor
                            are in any manner responsible or liable for any warranty, representation or guarantee,
                            express or implied, in fact or in law, relative to any prize, including express warranties
                            provided exclusively by a prize supplier that are sent along with a prize. </span><span
                            className="s1">Sweepstakes</span><span>
                            Entities shall not be liable for any injury, damage, loss, expense, accident, delay,
                            inconvenience, or irregularity that may be caused by or have contributed to: (1) any
                            wrongful, negligent, or unauthorized act or omission on the part of a prize supplier (if
                            applicable) or any of its agents, servants, employees, installers or independent
                            contractors, (2) any wrongful, negligent or unauthorized act or omission on the part of any
                            other person or entity not an employee of the Sponsor, or (3) any other cause, condition or
                            event whatsoever beyond the control of Sponsor or its parents, subsidiaries and/or
                            affiliated companies. The failure of the Sponsor to comply with any provision of these
                            Official Rules due to an act of God, hurricane, war, fire, riot, strikes, epidemics,
                            pandemics, demonstrations, earthquake, terrorism, act of public enemies, actions of
                            governmental authorities outside of the control of Sponsor (excepting compliance with
                            applicable codes and regulations) or other force majeure event will not be considered a
                            breach of these Official Rules.</span><b><span
                                className="Apple-converted-space">&nbsp;</span></b></li>
                    <li className="li6"><b>GOVERNING
                            LAW, JURISDICTION, ARBITRATION: </b><span>Any
                            controversy or claim arising out of or relating to (i) the Sweepstakes, (ii) the awarding or
                            redemption of a prize, and/or (iii) the determination of the scope or applicability of these
                            Official Rules or their enforcement or interpretation, shall be governed by and construed in
                            accordance with the substantive laws of the U.S. State of California without giving effect
                            to any choice of law or conflict of law rules or provisions that would cause the application
                            of any other state’s laws. Any controversy or claim arising out of or relating to this
                            agreement, its enforcement, arbitrability or interpretation shall be submitted to final and
                            binding arbitration, to be held in Los Angeles County, California, U.S. before a single
                            arbitrator, in accordance with California Code of Civil Procedure §§ 1280 et seq. The
                            arbitrator shall be selected by mutual agreement of Sponsor and the Entrant(s) bringing
                            forth the controversy or claim, which to the extent permissible must be brought individually
                            and not as part of a class/group (collectively the “Parties”) or, if the Parties cannot
                            agree, then by striking from a list of arbitrators supplied by the American Arbitration
                            Association or JAMS/Endispute. The arbitration shall be a confidential proceeding, closed to
                            the general public. The arbitrator shall issue a written opinion stating the essential
                            findings and conclusions upon which the arbitrator’s award is based. The Parties will share
                            equally in payment of the arbitrator’s fees and arbitration expenses and any other costs
                            unique to the arbitration hearing (recognizing that each side bears its own deposition,
                            witness, expert and attorneys’ fees, and other expenses to the same extent as if the matter
                            were being heard in court). Each Entrant agrees that his/her claim will be resolved
                            individually, exclusively by arbitration, without resort to any form of class action. Each
                            entrant further agrees that any claim/judgment/award in such arbitration shall be limited to
                            actual out-of-pocket costs incurred, including costs associated with participating in the
                            Sweepstakes but in no event attorneys' fees; and, under no circumstances, will an entrant be
                            entitled to awards. Each Entrant hereby waives all rights to claim punitive, incidental and
                            consequential damages and any other damages, other than for actual out-of-pocket expenses,
                            and any and all rights to have damages multiplied or increased. Should any term of this
                            section be deemed void by a tribunal of competent jurisdiction, unenforceable or contrary to
                            law, such term shall, but only to the extent necessary to bring this section within the
                            requirements of law, be deemed to be severed from the other terms of these Official Rules,
                            and the remainder of these Official Rules shall be given effect as if it had not included
                            the severed term herein. THE PARTIES HEREBY WAIVE THEIR RIGHT TO JURY TRIAL WITH RESPECT TO
                            ALL CLAIMS AND ISSUES ARISING OUT OF OR RELATING TO THIS AGREEMENT WHETHER SOUNDING IN
                            CONTRACT OR TORT AND INCLUDING ANY CLAIM FOR FRAUDULENT INDUCEMENT THEREOF.</span></li>
                    <li className="li6"><b>Rules/Winner Information:</b> To receive a copy of the Official Rules or the
                        Winner Information, send a self-addressed stamped envelope to:<b> </b><b>You
                            Bet Your Life QR Code Sweepstakes </b><span className="s1">“Rules”
                            or “Winner” (specify which), P.O. Box 6493</span><span>,
                        </span><span className="s1">Burbank,
                            CA 91510. All requests must be received by July 20, 2022.</span><span>
                            The Official Rules will be posted on the Microsite</span><b>
                        </b><span>during
                            the Sweepstakes.</span><span className="Apple-converted-space">&nbsp;</span>
                    </li>
                    <li className="li6"><b>Sponsor:</b><span className="s7">
                        </span><span>Otter
                            Creek Productions, 1999 S. Bundy Dr., Los Angeles, CA. 90025.</span></li>
                    <li className="li6"><b>Sweepstakes Administrator:</b> Enteractive Solutions Group, Inc., <span>1612
                            W. Olive Avenue, Suite 300, Burbank, CA 91506</span><span className="s1">.</span>
                    </li>
                </ol>
                <p className="p12"><b>©2021-2022 ESG.</b> These Official Rules may only be copied for personal use and
                    not
                    for any commercial purpose whatsoever. All rights reserved.</p>
                <p className="p13"><br /></p>
            </div>
        </Card>
    </div>
</div>
);
}

export default SweepstakesRules;