import React, { useEffect, useState, useContext } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { Route, Switch, withRouter} from 'react-router-dom';
import axios from './utils/axiosInstance';
import DeviceContext from './store/device-context';
import './App.scss';

import Landing from './pages/Landing/Landing';
import HowToPlay from './pages/HowToPlay/HowToPlay';
import Quiz from './pages/Quiz/Quiz';
import Loader from './components/UI/Loader/Loader';
import EmailSubmit from './pages/EmailSubmit/EmailSubmit';
import SweepstakesRules from './pages/SweepstakesRules/SweepstakesRules';
import Completed from './pages/Completed/Completed';
import SpotLights from './components/UI/SpotLights/SpotLights';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';
import NotAvailable from './pages/NotAvailable/NotAvailable';
import UserSubScriptionPurge from './pages/UserSubscriptionPurge/UserSubScriptionPurge';
import Error from './pages/Error/Error';
import { useCookies } from 'react-cookie';

const App = props => {
  const { ua } = props;
  const [ loading, setLoading ] = useState(true);
  const [ updatedDeviceData, setUpdatedDeviceData ] = useState(null);
  const { onSetDevice } = useContext(DeviceContext);
  const [cookies, setCookie] = useCookies(['a8FpVid'])
  
  useEffect(() => {
    if (updatedDeviceData) {
      onSetDevice(updatedDeviceData);
      setLoading(false)
    }
  }, [updatedDeviceData, onSetDevice]);

  useEffect(() => {

    const updateDevice = async(deviceToUpdate) => {
      try {
        const res = await axios.put(`device/${deviceToUpdate.uuid}`, deviceToUpdate, {
          headers: {
              'x-device-uuid': `${deviceToUpdate.uuid}`
          }
        });
        if (res && res.data) {
          setUpdatedDeviceData(res.data);
        }
      } catch(err) {
        setLoading(false);
        props.history.push('/error');
      }
    }

    const getUuid = async(deviceToUpdate) => {
      
      let fpPromiseParams = {token: process.env.REACT_APP_FPJS_TOKEN}
      if(process.env.REACT_APP_FPJS_ENDPOINT !== null) {
        fpPromiseParams.endpoint = process.env.REACT_APP_FPJS_ENDPOINT;
      }      
      const fpPromise = FingerprintJS.load(fpPromiseParams);
      try {
        const fp = await fpPromise
        const result = await fp.get();
        deviceToUpdate.uuid = result.visitorId;
        let cookieMaxAge = process.env.REACT_APP_FPJS_COOKIE_MAX_AGE_DAYS ? process.env.REACT_APP_FPJS_COOKIE_MAX_AGE_DAYS * (60 * 60 * 24) : 300; // (60 seconds * 60 minutes * 24 hours) 
        setCookie('a8FpVid', deviceToUpdate.uuid, {maxAge: cookieMaxAge , path : '/', sameSite: true}); 
        updateDevice(deviceToUpdate);
      } catch (error) {
        props.history.push('/error');
      }
    }

    const getDeviceDetails = () => {
      let deviceToUpdate = {
        id: '',
        uuid: cookies.a8FpVid,
        latitude: '',
        longitude: '',
        locale: '',
        manufacturer: '',
        model: '',
        os_name:'',
        os_version: '',
      };
      deviceToUpdate.os_name = ua.os.name;
      deviceToUpdate.os_version = ua.os.version;
      deviceToUpdate.manufacturer = ua.device.vendor;
      deviceToUpdate.model = ua.device.model;
      deviceToUpdate.locale = navigator.language || 'en-US';
      
      if(deviceToUpdate.uuid) {
        updateDevice(deviceToUpdate);
      } else {
        getUuid(deviceToUpdate);
      }
      
    }
    getDeviceDetails();
  }, [ua, setCookie, cookies.a8FpVid, props.history]);

  const loader = (
    <Loader />
  );

  const routes = (
    <Switch>
      <Route path='/' exact component={Landing} />
      <Route path='/howtoplay' exact component={HowToPlay} />
      <Route path='/quiz' exact component={Quiz} />
      <Route path='/completed' exact component={Completed} />
      <Route path='/redirect' exact component={(p) => {
        window.location.href = p.location.state.url;
        return null;
        }} />
      <Route path='/submit' exact component={EmailSubmit} />
      <Route path='/sweepstakesrules' exact component={SweepstakesRules} />
      <Route path='/terms' exact component={Terms} />
      <Route path='/privacy' exact component={Privacy} />
      <Route path='/contestnotavailable' exact component={NotAvailable} />
      <Route path='/user-subscription-purge' exact component={UserSubScriptionPurge} />
      <Route path='/error' exact component={Error} />
      <Route component={Error} />
    </Switch>
  );

  return (
    <div className="App">
      <SpotLights />
      { loading ? loader : routes }
    </div>
  );
}

export default withRouter(App);
